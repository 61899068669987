import React, { useState } from "react";

import css from "../LocalDetails.sass";
import Link from "~brokerage/components/shared/Link";
import Icon from "~brokerage/components/shared/Icon";
import EditType from "~brokerage/components/shared/ShowingScreen/Sidebar/ShowingType/EditType";
import { reasonText } from "~brokerage/constants/appointments";

const ShowingType = ({
  showingId,
  editable,
  appointmentReason,
  updateLocalDetails,
  permittedAppointmentReasons
}) => {
  const [editing, setEditing] = useState(false);

  const editLink = (
    <Link
      onClick={e => {
        e.stopPropagation();
        setEditing(!editing);
      }}
    >
      {editing ? (
        <>Cancel</>
      ) : (
        <>
          <Icon name="edit" /> Edit
        </>
      )}
    </Link>
  );
  return (
    <div className={css.section}>
      <h3 className={css.h3}>SHOWING TYPE {editable && editLink}</h3>
      {editing ? (
        <EditType
          appointmentReason={appointmentReason}
          showingId={showingId}
          setEditing={setEditing}
          permittedAppointmentReasons={permittedAppointmentReasons}
          onTypeChangeSuccess={type =>
            updateLocalDetails({ appointment_reason: type })
          }
        />
      ) : (
        <p className={css.text}>{reasonText[appointmentReason]}</p>
      )}
    </div>
  );
};

export default ShowingType;
