import React from "react";
import css from "./NotificationItem.sass";
import PropTypes from "prop-types";
import Dot from "~brokerage/app/components/shared/Dot";
import Link from "~brokerage/app/components/shared/Link";
import moment from "moment";
import { timeAgo } from "~brokerage/libs/helpers/TimeHelper";
import capitalize from "lodash/capitalize";

const getStatusString = status => {
  switch (status.toLowerCase()) {
    case "instant_declined":
      return "Declined";
    case "instant_approved":
      return "Approved";
    case "instant_pending":
    case "pending":
      return "Requested";
    case "time_changed":
      return "time changed & approval requested";
    case "new_participant":
      return "new participant";
    case "feedback_received":
      return "Feedback Received";
    case "feedback_requested":
      return "Feedback Requested";
    case "instructions_changed":
      return "access instructions changed";
    default:
      return capitalize(status);
  }
};

const NotificationItem = ({ notification }) => {
  const {
    address,
    created_at,
    duration,
    has_read,
    request_a_call,
    requested_time,
    showing_status,
    unique_id
  } = notification;

  const statusString = showing_status ? getStatusString(showing_status) : "";
  const dotVariant = has_read ? "read" : "unread";
  const reqTime =
    requested_time && moment(requested_time).format("ddd, MMM D h:mma");

  return (
    <div className={css.notification}>
      <div className={css.dot}>
        <Dot variant={dotVariant} />
      </div>
      <div className={css.content}>
        <div className={css.date}>{timeAgo(created_at)}</div>
        <div className={css.title}>
          Showing request{" "}
          <span
            className={css[statusString.toLowerCase().split(" ").join("-")]}
          >
            {statusString}
          </span>
          {request_a_call == true && (
            <>
              {" "}
              and requested a{" "}
              <span className={css[showing_status]}>callback</span> from you
            </>
          )}
        </div>
        <div className={css.subtitle}>
          <Link to={`/messages/showing/${unique_id}`}>{address}</Link>
          {reqTime && (
            <span
              className={css.reqTime}
            >{`Showing Date: ${reqTime} (${duration}mins)`}</span>
          )}
        </div>
      </div>
    </div>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired
};

export default NotificationItem;
