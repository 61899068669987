export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SHOW_LANDING_FOOTER = "SHOW_LANDING_FOOTER";
export const HIDE_LANDING_FOOTER = "HIDE_LANDING_FOOTER";

// SHOWINGS
export const SHOWINGS_REQUEST = "SHOWINGS_REQUEST";
export const SHOWINGS_SUCCESS = "SHOWINGS_SUCCESS";
export const SHOWINGS_FAILURE = "SHOWINGS_FAILURE";
export const SHOWINGS_FILTER_CHANGE = "SHOWINGS_FILTER_CHANGE";
export const SHOWINGS_PAGE_SCROLLED = "SHOWINGS_PAGE_SCROLLED";
export const SHOWINGS_PAGE_SCROLLED_FAILURE = "SHOWINGS_PAGE_SCROLLED_FAILURE";
export const SHOWINGS_AGENT_NAME_REQUEST = "SHOWINGS_AGENT_NAME_REQUEST";
export const SHOWINGS_AGENT_NAME_SUCCESS = "SHOWINGS_AGENT_NAME_SUCCESS";
export const SHOWINGS_AGENT_NAME_FAILURE = "SHOWINGS_AGENT_NAME_FAILURE";
export const SHOWINGS_PAGE_SCROLLED_SUCCESS = "SHOWINGS_PAGE_SCROLLED_SUCCESS";
export const SHOWINGS_COUNTS_REQUEST = "SHOWINGS_COUNTS_REQUEST";
export const SHOWINGS_COUNTS_SUCCESS = "SHOWINGS_COUNTS_SUCCESS";
export const SHOWINGS_COUNTS_FAILURE = "SHOWINGS_COUNTS_FAILURE";
export const AVAILABLE_ROLES_REQUEST = "AVAILABLE_ROLES_REQUEST";
export const AVAILABLE_ROLES_SUCCESS = "AVAILABLE_ROLES_SUCCESS";
export const AVAILABLE_ROLES_FAILURE = "AVAILABLE_ROLES_FAILURE";
export const SHOWINGS_UNREAD_COUNT_REQUEST = "SHOWINGS_UNREAD_COUNT_REQUEST";
export const SHOWINGS_UNREAD_COUNT_SUCCESS = "SHOWINGS_UNREAD_COUNT_SUCCESS";
export const SHOWINGS_UNREAD_COUNT_FAILURE = "SHOWINGS_UNREAD_COUNT_FAILURE";
export const SHOWINGS_SHOWING_CHANGED_BY_MESSAGE =
  "SHOWINGS_SHOWING_CHANGED_BY_MESSAGE";

export const SHOWINGS_UPDATE_LIST = "SHOWINGS_UPDATE_LIST";
export const SHOWINGS_APPROVE_SHOWING_REQUEST =
  "SHOWINGS_APPROVE_SHOWING_REQUEST";
export const SHOWINGS_APPROVE_SHOWING_SUCCESS =
  "SHOWINGS_APPROVE_SHOWING_SUCCESS";
export const SHOWINGS_APPROVE_SHOWING_FAILURE =
  "SHOWINGS_APPROVE_SHOWING_FAILURE";

export const SHOWINGS_DECLINE_SHOWING_REQUEST =
  "SHOWINGS_DECLINE_SHOWING_REQUEST";
export const SHOWINGS_DECLINE_SHOWING_SUCCESS =
  "SHOWINGS_DECLINE_SHOWING_SUCCESS";
export const SHOWINGS_DECLINE_SHOWING_FAILURE =
  "SHOWINGS_DECLINE_SHOWING_FAILURE";

export const SCROLL_SHOWING_CHAT_THREAD_REQUEST =
  "SCROLL_SHOWING_CHAT_THREAD_REQUEST";
export const SCROLL_SHOWING_CHAT_THREAD_SUCCESS =
  "SCROLL_SHOWING_CHAT_THREAD_SUCCESS";
export const SCROLL_SHOWING_CHAT_THREAD_FAILURE =
  "SCROLL_SHOWING_CHAT_THREAD_FAILURE";

export const SHOWING_REQUEST = "SHOWING_REQUEST";
export const SHOWING_SUCCESS = "SHOWING_SUCCESS";
export const SHOWING_FAILURE = "SHOWING_FAILURE";
export const SHOWING_CHANGE_REQUEST = "SHOWING_CHANGE_REQUEST";
export const SHOWING_CHANGE_SUCCESS = "SHOWING_CHANGE_SUCCESS";
export const SHOWING_CHANGE_FAILURE = "SHOWING_CHANGE_FAILURE";
export const SHOWING_CHANGED_BY_MESSAGE = "SHOWING_CHANGED_BY_MESSAGE";
export const SHOWING_MESSAGES_REQUEST = "SHOWING_MESSAGES_REQUEST";
export const SHOWING_MESSAGES_SUCCESS = "SHOWING_MESSAGES_SUCCESS";
export const SHOWING_MESSAGES_FAILURE = "SHOWING_MESSAGES_FAILURE";
export const SHOWING_MESSAGES_ADD = "SHOWING_MESSAGES_ADD";
export const SHOWING_AVAILABLE_AGENTS_REQUEST =
  "SHOWING_AVAILABLE_AGENTS_REQUEST";
export const SHOWING_AVAILABLE_AGENTS_SUCCESS =
  "SHOWING_AVAILABLE_AGENTS_SUCCESS";
export const SHOWING_AVAILABLE_AGENTS_FAILURE =
  "SHOWING_AVAILABLE_AGENTS_FAILURE";
export const SHOWING_MESSAGE_REQUEST = "SHOWING_MESSAGE_REQUEST";
export const SHOWING_MESSAGE_SUCCESS = "SHOWING_MESSAGE_SUCCESS";
export const SHOWING_MESSAGE_FAILURE = "SHOWING_MESSAGE_ERROR";
export const SHOWING_NOTE_MESSAGE_REQUEST = "SHOWING_NOTE_MESSAGE_REQUEST";
export const SHOWING_NOTE_MESSAGE_SUCCESS = "SHOWING_NOTE_MESSAGE_SUCCESS";
export const SHOWING_NOTE_MESSAGE_FAILURE = "SHOWING_NOTE_MESSAGE_FAILUER";
export const FETCH_PUBNUB_DATA_REQUEST = "FETCH_PUBNUB_DATA_REQUEST";
export const FETCH_PUBNUB_DATA_SUCCESS = "FETCH_PUBNUB_DATA_SUCCESS";
export const FETCH_PUBNUB_DATA_FAILURE = "FETCH_PUBNUB_DATA_FAILURE";
export const SHOWING_LISTING_TEAM_MEMBERS_REQUEST =
  "SHOWING_LISTING_TEAM_MEMBERS_REQUEST";
export const SHOWING_LISTING_TEAM_MEMBERS_SUCCESS =
  "SHOWING_LISTING_TEAM_MEMBERS_SUCCESS";
export const SHOWING_LISTING_TEAM_MEMBERS_FAILURE =
  "SHOWING_LISTING_TEAM_MEMBERS_FAILURE";
export const SHOWING_BUYER_TEAM_MEMBERS_REQUEST =
  "SHOWING_BUYER_TEAM_MEMBERS_REQUEST";
export const SHOWING_BUYER_TEAM_MEMBERS_SUCCESS =
  "SHOWING_BUYER_TEAM_MEMBERS_SUCCESS";
export const SHOWING_BUYER_TEAM_MEMBERS_FAILURE =
  "SHOWING_BUYER_TEAM_MEMBERS_FAILURE";
export const SHOWING_EDIT_INSTRUCTIONS_SUBMIT_REQUEST =
  "SHOWING_EDIT_INSTRUCTIONS_SUBMIT_REQUEST";
export const SHOWING_EDIT_INSTRUCTIONS_SUBMIT_SUCCESS =
  "SHOWING_EDIT_INSTRUCTIONS_SUBMIT_SUCCESS";
export const SHOWING_EDIT_INSTRUCTIONS_SUBMIT_FAILURE =
  "SHOWING_EDIT_INSTRUCTIONS_SUBMIT_FAILURE";
export const UPDATE_SHOWING_TEAM_INSTRUCTIONS_REQUEST =
  "UPDATE_SHOWING_TEAM_INSTRUCTIONS_REQUEST";
export const UPDATE_SHOWING_TEAM_INSTRUCTIONS_SUCCESS =
  "UPDATE_SHOWING_TEAM_INSTRUCTIONS_SUCCESS";
export const UPDATE_SHOWING_TEAM_INSTRUCTIONS_FAILURE =
  "UPDATE_SHOWING_TEAM_INSTRUCTIONS_FAILURE";
export const SHOWING_CLEAR_AVAILABLE_AGENTS = "SHOWING_CLEAR_AVAILABLE_AGENTS";
export const SHOWING_CANCEL_SHOWING_REQUEST = "SHOWING_CANCEL_SHOWING_REQUEST";
export const SHOWING_CANCEL_SHOWING_SUCCESS = "SHOWING_CANCEL_SHOWING_SUCCESS";
export const SHOWING_CANCEL_SHOWING_FAILURE = "SHOWING_CANCEL_SHOWING_FAILURE";

export const SHOWING_SELLER_TENANT_INVOLVEMENT_REQUEST =
  "SHOWING_SELLER_TENANT_INVOLVEMENT_REQUEST";
export const SHOWING_SELLER_TENANT_INVOLVEMENT_SUCCESS =
  "SHOWING_SELLER_TENANT_INVOLVEMENT_SUCCESS";
export const SHOWING_SELLER_TENANT_INVOLVEMENT_FAILURE =
  "SHOWING_SELLER_TENANT_INVOLVEMENT_FAILURE";
export const MARK_SHOWING_AS_READ = "MARK_SHOWING_AS_READ";
export const SHOWING_ADD_AGENT_TO_MY_TEAM = "SHOWING_ADD_AGENT_TO_MY_TEAM";
export const SHOWING_REMOVE_AGENT_FROM_MY_TEAM =
  "SHOWING_REMOVE_AGENT_FROM_MY_TEAM";

export const SHOWING_ADD_AGENT_TO_MY_TEAM_REQUEST =
  "SHOWING_ADD_AGENT_TO_MY_TEAM_REQUEST";
export const SHOWING_ADD_AGENT_TO_MY_TEAM_SUCCESS =
  "SHOWING_ADD_AGENT_TO_MY_TEAM_SUCCESS";
export const SHOWING_ADD_AGENT_TO_MY_TEAM_FAILURE =
  "SHOWING_ADD_AGENT_TO_MY_TEAM_FAILURE";
export const SHOWING_REMOVE_AGENT_FROM_MY_TEAM_REQUEST =
  "SHOWING_REMOVE_AGENT_FROM_MY_TEAM_REQUEST";
export const SHOWING_REMOVE_AGENT_FROM_MY_TEAM_SUCCESS =
  "SHOWING_REMOVE_AGENT_FROM_MY_TEAM_SUCCESS";
export const SHOWING_REMOVE_AGENT_FROM_MY_TEAM_FAILURE =
  "SHOWING_REMOVE_AGENT_FROM_MY_TEAM_FAILURE";

export const SHOWING_UPDATE_SHOWING_TYPE = "SHOWING_UPDATE_SHOWING_TYPE";

export const SHOWING_UNHIDE_FEEDBACK_REQUEST =
  "SHOWING_UNHIDE_FEEDBACK_REQUEST";
export const SHOWING_UNHIDE_FEEDBACK_SUCCESS =
  "SHOWING_UNHIDE_FEEDBACK_SUCCESS";
export const SHOWING_UNHIDE_FEEDBACK_FAILURE =
  "SHOWING_UNHIDE_FEEDBACK_FAILURE";
export const SHOWING_HIDE_FEEDBACK_REQUEST = "SHOWING_HIDE_FEEDBACK_REQUEST";
export const SHOWING_HIDE_FEEDBACK_SUCCESS = "SHOWING_HIDE_FEEDBACK_SUCCESS";
export const SHOWING_HIDE_FEEDBACK_FAILURE = "SHOWING_HIDE_FEEDBACK_FAILURE";
export const SHOWING_FEEDBACK_ANSWER_REQUEST =
  "SHOWING_FEEDBACK_ANSWER_REQUEST";
export const SHOWING_FEEDBACK_ANSWER_SUCCESS =
  "SHOWING_FEEDBACK_ANSWER_SUCCESS";
export const SHOWING_FEEDBACK_ANSWER_FAILURE =
  "SHOWING_FEEDBACK_ANSWER_FAILURE";
export const SHOWING_FEEDBACK_QUESTIONS_REQUEST =
  "SHOWING_FEEDBACK_QUESTIONS_REQUEST";
export const SHOWING_FEEDBACK_QUESTIONS_SUCCESS =
  "SHOWING_FEEDBACK_QUESTIONS_SUCCESS";
export const SHOWING_FEEDBACK_QUESTIONS_FAILURE =
  "SHOWING_FEEDBACK_QUESTIONS_FAILURE";

export const NEW_SHOWING_PRESERVE_GLOBAL_STATE =
  "NEW_SHOWING_PRESERVE_GLOBAL_STATE";
export const NEW_SHOWING_CLEAR_PRESERVE_GLOBAL_STATE =
  "NEW_SHOWING_CLEAR_PRESERVE_GLOBAL_STATE";

export const NEW_SHOWING_LISTINGS_REQUEST = "NEW_SHOWING_LISTINGS_REQUEST";
export const NEW_SHOWING_LISTINGS_SUCCESS = "NEW_SHOWING_LISTINGS_SUCCESS";
export const NEW_SHOWING_LISTINGS_FAILURE = "NEW_SHOWING_LISTINGS_FAILURE";

export const NEW_SHOWING_SELECTED_LISTING_REQUEST =
  "NEW_SHOWING_SELECTED_LISTING_REQUEST";
export const NEW_SHOWING_SELECTED_LISTING_SUCCESS =
  "NEW_SHOWING_SELECTED_LISTING_SUCCESS";
export const NEW_SHOWING_SELECTED_LISTING_FAILURE =
  "NEW_SHOWING_SELECTED_LISTING_FAILURE";

export const NEW_SHOWING_SELECTED_LISTING_SHOWING_INSTRUCTIONS_REQUEST =
  "NEW_SHOWING_SELECTED_LISTING_SHOWING_INSTRUCTIONS_REQUEST";
export const NEW_SHOWING_SELECTED_LISTING_SHOWING_INSTRUCTIONS_SUCCESS =
  "NEW_SHOWING_SELECTED_LISTING_SHOWING_INSTRUCTIONS_SUCCESS";
export const NEW_SHOWING_SELECTED_LISTING_SHOWING_INSTRUCTIONS_FAILURE =
  "NEW_SHOWING_SELECTED_LISTING_SHOWING_INSTRUCTIONS_FAILURE";

export const NEW_SHOWING_SELECTED_LISTING_SELLER_TENANTS_REQUEST =
  "NEW_SHOWING_SELECTED_LISTING_SELLER_TENANTS_REQUEST";
export const NEW_SHOWING_SELECTED_LISTING_SELLER_TENANTS_SUCCESS =
  "NEW_SHOWING_SELECTED_LISTING_SELLER_TENANTS_SUCCESS";
export const NEW_SHOWING_SELECTED_LISTING_SELLER_TENANTS_FAILURE =
  "NEW_SHOWING_SELECTED_LISTING_SELLER_TENANTS_FAILURE";

export const NEW_SHOWING_SELECTED_LISTING_TEAM_MEMBERS_REQUEST =
  "NEW_SHOWING_SELECTED_LISTING_TEAM_MEMBERS_REQUEST";
export const NEW_SHOWING_SELECTED_LISTING_TEAM_MEMBERS_SUCCESS =
  "NEW_SHOWING_SELECTED_LISTING_TEAM_MEMBERS_SUCCESS";
export const NEW_SHOWING_SELECTED_LISTING_TEAM_MEMBERS_FAILURE =
  "NEW_SHOWING_SELECTED_LISTING_TEAM_MEMBERS_FAILURE";

export const NEW_SHOWING_SELECTED_LISTING_RESTRICTIONS_REQUEST =
  "NEW_SHOWING_SELECTED_LISTING_RESTRICTIONS_REQUEST";
export const NEW_SHOWING_SELECTED_LISTING_RESTRICTIONS_SUCCESS =
  "NEW_SHOWING_SELECTED_LISTING_RESTRICTIONS_SUCCESS";
export const NEW_SHOWING_SELECTED_LISTING_RESTRICTIONS_FAILURE =
  "NEW_SHOWING_SELECTED_LISTING_RESTRICTIONS_FAILURE";

export const NEW_SHOWING_CLEAR_SELECTED_LISTING =
  "NEW_SHOWING_CLEAR_SELECTED_LISTING";
export const NEW_SHOWING_AGENTS_REQUEST = "NEW_SHOWING_AGENTS_REQUEST";
export const NEW_SHOWING_AGENTS_SUCCESS = "NEW_SHOWING_AGENTS_SUCCESS";
export const NEW_SHOWING_AGENTS_FAILURE = "NEW_SHOWING_AGENTS_FAILURE";
export const NEW_SHOWING_AGENTS_CLEAR = "NEW_SHOWING_AGENTS_CLEAR";
export const NEW_SHOWING_SELECTED_AGENT = "NEW_SHOWING_SELECTED_AGENT";
export const NEW_SHOWING_CLEAR_SELECTED_AGENT =
  "NEW_SHOWING_CLEAR_SELECTED_AGENT";
export const NEW_SHOWING_SELECTED_DATE = "NEW_SHOWING_SELECTED_DATE";
export const NEW_SHOWING_SELECTED_TIME = "NEW_SHOWING_SELECTED_TIME";
export const NEW_SHOWING_SELECTED_DURATION = "NEW_SHOWING_SELECTED_DURATION";
export const NEW_SHOWING_SHOWINGS_REQUEST = "NEW_SHOWING_SHOWINGS_REQUEST";
export const NEW_SHOWING_SHOWINGS_SUCCESS = "NEW_SHOWING_SHOWINGS_SUCCESS";
export const NEW_SHOWING_SHOWINGS_FAILURE = "NEW_SHOWING_SHOWINGS_FAILURE";
export const NEW_SHOWING_RESTRICTIONS_REQUEST =
  "NEW_SHOWING_RESTRICTIONS_REQUEST";
export const NEW_SHOWING_RESTRICTIONS_SUCCESS =
  "NEW_SHOWING_RESTRICTIONS_SUCCESS";
export const NEW_SHOWING_RESTRICTIONS_FAILURE =
  "NEW_SHOWING_RESTRICTIONS_FAILURE";
export const NEW_SHOWING_STEP_CHANGE = "NEW_SHOWING_STEP_CHANGE";
export const NEW_SHOWING_SAVE_REQUEST = "NEW_SHOWING_SAVE_REQUEST";
export const NEW_SHOWING_SAVE_SUCCESS = "NEW_SHOWING_SAVE_SUCCESS";
export const NEW_SHOWING_SAVE_FAILURE = "NEW_SHOWING_SAVE_FAILURE";
export const NEW_SHOWING_CONVERT_REQUEST = "NEW_SHOWING_CONVERT_REQUEST";
export const NEW_SHOWING_CONVERT_SUCCESS = "NEW_SHOWING_CONVERT_SUCCESS";
export const NEW_SHOWING_CONVERT_FAILURE = "NEW_SHOWING_CONVERT_FAILURE";
export const NEW_SHOWING_UPDATE_CONFLICTS = "NEW_SHOWING_UPDATE_CONFLICTS";

export const NEW_SHOWING_LISTING_STATUSES_REQUEST =
  "NEW_SHOWING_LISTING_STATUSES_REQUEST";
export const NEW_SHOWING_LISTING_STATUSES_SUCCESS =
  "NEW_SHOWING_LISTING_STATUSES_SUCCESS";
export const NEW_SHOWING_LISTING_STATUSES_FAILURE =
  "NEW_SHOWING_LISTING_STATUSES_FAILURE";

export const NEW_SHOWING_CLOSE_MODAL = "NEW_SHOWING_CLOSE_MODAL";
export const NEW_SHOWING_CLEAR_FORM = "NEW_SHOWING_CLEAR_FORM";

export const FETCH_FILTER_AGENTS_REQUEST = "FETCH_FILTER_AGENTS_REQUEST";
export const FETCH_FILTER_AGENTS_SUCCESS = "FETCH_FILTER_AGENTS_SUCCESS";
export const FETCH_FILTER_AGENTS_FAILURE = "FETCH_FILTER_AGENTS_FAILURE";
export const UPDATE_SHOWING_MESSAGE_HAS_READ =
  "UPDATE_SHOWING_MESSAGE_HAS_READ";

// LISTINGS
export const LISTINGS_REQUEST = "LISTINGS_REQUEST";
export const LISTINGS_SUCCESS = "LISTINGS_SUCCESS";
export const LISTINGS_FAILURE = "LISTINGS_FAILURE";
export const LISTINGS_FILTER_CHANGE = "LISTINGS_FILTER_CHANGE";

export const LISTING_CATEGORIES_REQUEST = "LISTING_CATEGORIES_REQUEST";
export const LISTING_CATEGORIES_SUCCESS = "LISTING_CATEGORIES_SUCCESS";
export const LISTING_CATEGORIES_FAILURE = "LISTING_CATEGORIES_FAILURE";
export const LISTING_CATEGORIES_SELECT = "LISTING_CATEGORIES_SELECT";

export const LISTING_AGENT_SELECT = "LISTING_AGENT_SELECT";

export const LISTING_STATUSES_REQUEST = "LISTING_STATUSES_REQUEST";
export const LISTING_STATUSES_SUCCESS = "LISTING_STATUSES_SUCCESS";
export const LISTING_STATUSES_FAILURE = "LISTING_STATUSES_FAILURE";
export const LISTING_STATUSES_SELECT = "LISTING_STATUSES_SELECT";

export const LISTING_REQUEST = "LISTING_REQUEST";
export const LISTING_SUCCESS = "LISTING_SUCCESS";
export const LISTING_FAILURE = "LISTING_FAILURE";

export const LISTING_LIST_DISPLAY_TOGGLE = "LISTING_LIST_DISPLAY_TOGGLE";

export const LISTING_SHOWING_DETAILS_REQUEST =
  "LISTING_SHOWING_DETAILS_REQUEST";
export const LISTING_SHOWING_DETAILS_SUCCESS =
  "LISTING_SHOWING_DETAILS_SUCCESS";
export const LISTING_SHOWING_DETAILS_FAILURE =
  "LISTING_SHOWING_DETAILS_FAILURE";

export const LISTING_SHOWING_DETAILS_CHANGE = "LISTING_SHOWING_DETAILS_CHANGE";

export const LISTING_SHOWING_INSTRUCTIONS_REQUEST =
  "LISTING_SHOWING_INSTRUCTIONS_REQUEST";
export const LISTING_SHOWING_INSTRUCTIONS_SUCCESS =
  "LISTING_SHOWING_INSTRUCTIONS_SUCCESS";
export const LISTING_SHOWING_INSTRUCTIONS_FAILURE =
  "LISTING_SHOWING_INSTRUCTIONS_FAILURE";

export const LISTING_SHOWING_INSTRUCTIONS_CHANGE =
  "LISTING_SHOWING_INSTRUCTIONS_CHANGE";

export const LISTING_PREVIOUS_ADVANCE_NOTICE_DURATION =
  "LISTING_PREVIOUS_ADVANCE_NOTICE_DURATION";

export const LISTING_SHOWING_INSTRUCTIONS_SAVE_REQUEST =
  "LISTING_SHOWING_INSTRUCTIONS_SAVE_REQUEST";
export const LISTING_SHOWING_INSTRUCTIONS_SAVE_SUCCESS =
  "LISTING_SHOWING_INSTRUCTIONS_SAVE_SUCCESS";
export const LISTING_SHOWING_INSTRUCTIONS_SAVE_FAILURE =
  "LISTING_SHOWING_INSTRUCTIONS_SAVE_FAILURE";

export const LISTING_SHOWING_DETAILS_AVAILABLE_AGENTS_REQUEST =
  "LISTING_SHOWING_DETAILS_AVAILABLE_AGENTS_REQUEST";
export const LISTING_SHOWING_DETAILS_AVAILABLE_AGENTS_SUCCESS =
  "LISTING_SHOWING_DETAILS_AVAILABLE_AGENTS_SUCCESS";
export const LISTING_SHOWING_DETAILS_AVAILABLE_AGENTS_FAILURE =
  "LISTING_SHOWING_DETAILS_AVAILABLE_AGENTS_FAILURE";
export const LISTING_SHOWING_DETAILS_AVAILABLE_AGENTS_CLEAR =
  "LISTING_SHOWING_DETAILS_AVAILABLE_AGENTS_CLEAR";

export const LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_REQUEST =
  "LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_REQUEST";
export const LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_SUCCESS =
  "LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_SUCCESS";
export const LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_FAILURE =
  "LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_FAILURE";
export const LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_CLEAR =
  "LISTING_SHOWING_DETAILS_AVAILABLE_SELLERS_CLEAR";

export const LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_REQUEST =
  "LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_REQUEST";
export const LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_SUCCESS =
  "LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_SUCCESS";
export const LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_FAILURE =
  "LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_FAILURE";
export const LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_CLEAR =
  "LISTING_SHOWING_DETAILS_AVAILABLE_MEMBERS_CLEAR";
export const LISTING_SHOWING_DETAILS_CHANGE_STEP =
  "LISTING_SHOWING_DETAILS_CHANGE_STEP";

export const LISTING_SELLER_TENANT_INVOLVEMENT_REQUEST =
  "LISTING_SELLER_TENANT_INVOLVEMENT_REQUEST";
export const LISTING_SELLER_TENANT_INVOLVEMENT_SUCCESS =
  "LISTING_SELLER_TENANT_INVOLVEMENT_SUCCESS";
export const LISTING_SELLER_TENANT_INVOLVEMENT_FAILURE =
  "LISTING_SELLER_TENANT_INVOLVEMENT_FAILURE";

export const LISTING_SELLER_TENANT_ADD_PARTICIPANT =
  "LISTING_SELLER_TENANT_ADD_PARTICIPANT";
export const LISTING_SELLER_TENANT_REMOVE_PARTICIPANT =
  "LISTING_SELLER_TENANT_REMOVE_PARTICIPANT";

export const LISTING_SELLER_TENANT_INVOLVEMENT_BATCH_UPDATE_REQUEST =
  "LISTING_SELLER_TENANT_INVOLVEMENT_BATCH_UPDATE_REQUEST";
export const LISTING_SELLER_TENANT_INVOLVEMENT_BATCH_UPDATE_SUCCESS =
  "LISTING_SELLER_TENANT_INVOLVEMENT_BATCH_UPDATE_SUCCESS";
export const LISTING_SELLER_TENANT_INVOLVEMENT_BATCH_UPDATE_FAILURE =
  "LISTING_SELLER_TENANT_INVOLVEMENT_BATCH_UPDATE_FAILURE";

export const LISTING_SELLER_TENANT_INVOLVEMENT_CHANGE =
  "LISTING_SELLER_TENANT_INVOLVEMENT_CHANGE";

export const LISTING_ADD_RESTRICTION_REQUEST =
  "LISTING_ADD_RESTRICTION_REQUEST";
export const LISTING_ADD_RESTRICTION_SUCCESS =
  "LISTING_ADD_RESTRICTION_SUCCESS";
export const LISTING_ADD_RESTRICTION_FAILURE =
  "LISTING_ADD_RESTRICTION_FAILURE";
export const LISTING_REMOVE_RESTRICTION_REQUEST =
  "LISTING_REMOVE_RESTRICTION_REQUEST";
export const LISTING_REMOVE_RESTRICTION_SUCCESS =
  "LISTING_REMOVE_RESTRICTION_SUCCESS";
export const LISTING_REMOVE_RESTRICTION_FAILURE =
  "LISTING_REMOVE_RESTRICTION_FAILURE";
export const LISTING_UPDATE_RESTRICTION_REQUEST =
  "LISTING_UPDATE_RESTRICTION_REQUEST";
export const LISTING_UPDATE_RESTRICTION_SUCCESS =
  "LISTING_UPDATE_RESTRICTION_SUCCESS";
export const LISTING_UPDATE_RESTRICTION_FAILURE =
  "LISTING_UPDATE_RESTRICTION_FAILURE";
export const FETCH_LISTING_RESTRICTIONS_REQUEST =
  "FETCH_LISTING_RESTRICTIONS_REQUEST";
export const FETCH_LISTING_RESTRICTIONS_SUCCESS =
  "FETCH_LISTING_RESTRICTIONS_SUCCESS";
export const FETCH_LISTING_RESTRICTIONS_FAILURE =
  "FETCH_LISTING_RESTRICTIONS_FAILURE";

export const FETCH_DEFAULT_RESTRICTION_REQUEST =
  "FETCH_DEFAULT_RESTRICTION_REQUEST";
export const FETCH_DEFAULT_RESTRICTION_SUCCESS =
  "FETCH_DEFAULT_RESTRICTION_SUCCESS";
export const FETCH_DEFAULT_RESTRICTION_FAILURE =
  "FETCH_DEFAULT_RESTRICTION_FAILURE";
export const ADD_DEFAULT_RESTRICTION_REQUEST =
  "ADD_DEFAULT_RESTRICTION_REQUEST";
export const ADD_DEFAULT_RESTRICTION_SUCCESS =
  "ADD_DEFAULT_RESTRICTION_SUCCESS";
export const ADD_DEFAULT_RESTRICTION_FAILURE =
  "ADD_DEFAULT_RESTRICTION_FAILURE";
export const UPDATE_DEFAULT_RESTRICTION_REQUEST =
  "UPDATE_DEFAULT_RESTRICTION_REQUEST";
export const UPDATE_DEFAULT_RESTRICTION_SUCCESS =
  "UPDATE_DEFAULT_RESTRICTION_SUCCESS";
export const UPDATE_DEFAULT_RESTRICTION_FAILURE =
  "UPDATE_DEFAULT_RESTRICTION_FAILURE";
export const REMOVE_DEFAULT_RESTRICTION_REQUEST =
  "REMOVE_DEFAULT_RESTRICTION_REQUEST";
export const REMOVE_DEFAULT_RESTRICTION_SUCCESS =
  "REMOVE_DEFAULT_RESTRICTION_SUCCESS";
export const REMOVE_DEFAULT_RESTRICTION_FAILURE =
  "REMOVE_DEFAULT_RESTRICTION_FAILURE";

export const LISTING_UPDATE_SHOWING_COORDINATOR_ENABLED_REQUEST =
  "LISTING_UPDATE_SHOWING_COORDINATOR_ENABLED_REQUEST";
export const LISTING_UPDATE_SHOWING_COORDINATOR_ENABLED_SUCCESS =
  "LISTING_UPDATE_SHOWING_COORDINATOR_ENABLED_SUCCESS";
export const LISTING_UPDATE_SHOWING_COORDINATOR_ENABLED_FAILURE =
  "LISTING_UPDATE_SHOWING_COORDINATOR_ENABLED_FAILURE";

// TEAMS
export const TEAMS_OPTIONS_REQUEST = "TEAMS_OPTIONS_REQUEST";
export const TEAMS_OPTIONS_SUCCESS = "TEAMS_OPTIONS_SUCCESS";
export const TEAMS_OPTIONS_FAILURE = "TEAMS_OPTIONS_FAILURE";

export const MY_ORGANIZATION_MEMBERS_REQUEST =
  "MY_ORGANIZATION_MEMBERS_REQUEST";
export const MY_ORGANIZATION_MEMBERS_SUCCESS =
  "MY_ORGANIZATION_MEMBERS_SUCCESS";
export const MY_ORGANIZATION_MEMBERS_FAILURE =
  "MY_ORGANIZATION_MEMBERS_FAILURE";

export const TEAM_INSTRUCTIONS_UPDATE = "TEAM_INSTRUCTIONS_UPDATE";

// TEAM_MEMBERS
export const LISTING_TEAM_MEMBERS_REQUEST = "LISTING_TEAM_MEMBERS_REQUEST";
export const LISTING_TEAM_MEMBERS_SUCCESS = "LISTING_TEAM_MEMBERS_SUCCESS";
export const LISTING_TEAM_MEMBERS_FAILURE = "LISTING_TEAM_MEMBERS_FAILURE";

// DIFF CACHE
export const LISTING_TEAM_MEMBERS_ADD_MEMBER =
  "LISTING_TEAM_MEMBERS_ADD_MEMBER";
export const LISTING_TEAM_MEMBERS_REMOVE_MEMBER =
  "LISTING_TEAM_MEMBERS_REMOVE_MEMBER";
export const LISTING_TEAM_MEMBERS_UPDATE_MEMBER =
  "LISTING_TEAM_MEMBERS_UPDATE_MEMBER";

export const LISTING_TEAM_BATCH_UPDATE_REQUEST =
  "LISTING_TEAM_BATCH_UPDATE_REQUEST";
export const LISTING_TEAM_BATCH_UPDATE_SUCCESS =
  "LISTING_TEAM_BATCH_UPDATE_SUCCESS";
export const LISTING_TEAM_BATCH_UPDATE_FAILURE =
  "LISTING_TEAM_BATCH_UPDATE_FAILURE";

// SAVE BUTTON
export const LISTING_SHOWING_DETAILS_DISABLE_SAVE_BUTTON =
  "LISTING_SHOWING_DETAILS_DISABLE_SAVE_BUTTON";
export const LISTING_SHOWING_DETAILS_ENABLE_SAVE_BUTTON =
  "LISTING_SHOWING_DETAILS_ENABLE_SAVE_BUTTON";

//PEOPLE
export const PEOPLE_REQUEST = "PEOPLE_REQUEST";
export const PEOPLE_SUCCESS = "PEOPLE_SUCCESS";
export const PEOPLE_FAILURE = "PEOPLE_FAILURE";

export const PEOPLE_COUNTS_REQUEST = "PEOPLE_COUNTS_REQUEST";
export const PEOPLE_COUNTS_SUCCESS = "PEOPLE_COUNTS_SUCCESS";
export const PEOPLE_COUNTS_FAILURE = "PEOPLE_COUNTS_FAILURE";

export const PEOPLE_CATEGORIES_REQUEST = "PEOPLE_CATEGORIES_REQUEST";
export const PEOPLE_CATEGORIES_SUCCESS = "PEOPLE_CATEGORIES_SUCCESS";
export const PEOPLE_CATEGORIES_FAILURE = "PEOPLE_CATEGORIES_FAILURE";

export const CUSTOMER_CATEGORIES_REQUEST = "CUSTOMER_CATEGORIES_REQUEST";
export const CUSTOMER_CATEGORIES_SUCCESS = "CUSTOMER_CATEGORIES_SUCCESS";
export const CUSTOMER_CATEGORIES_FAILURE = "CUSTOMER_CATEGORIES_FAILURE";

export const CUSTOMER_SHOWINGS_REQUEST = "CUSTOMER_SHOWINGS_REQUEST";
export const CUSTOMER_SHOWINGS_SUCCESS = "CUSTOMER_SHOWINGS_SUCCESS";
export const CUSTOMER_SHOWINGS_FAILURE = "CUSTOMER_SHOWINGS_FAILURE";

export const CUSTOMER_LISTINGS_REQUEST = "CUSTOMER_LISTINGS_REQUEST";
export const CUSTOMER_LISTINGS_SUCCESS = "CUSTOMER_LISTINGS_SUCCESS";
export const CUSTOMER_LISTINGS_FAILURE = "CUSTOMER_LISTINGS_FAILURE";

export const PERSON_REQUEST = "PERSON_REQUEST";
export const PERSON_SUCCESS = "PERSON_SUCCESS";
export const PERSON_FAILURE = "PERSON_FAILURE";
export const PERSON_UPDATE = "PERSON_UPDATE";

export const PERSON_LISTINGS_REQUEST = "PERSON_LISTINGS_REQUEST";
export const PERSON_LISTINGS_SUCCESS = "PERSON_LISTINGS_SUCCESS";
export const PERSON_LISTINGS_FAILURE = "PERSON_LISTINGS_FAILURE";

export const PEOPLE_SET_Q = "PEOPLE_SET_Q";

export const PERSONAL_ASSISTANTS_REQUEST = "PERSONAL_ASSISTANTS_REQUEST";
export const PERSONAL_ASSISTANTS_SUCCESS = "PERSONAL_ASSISTANTS_SUCCESS";
export const PERSONAL_ASSISTANTS_FAILURE = "PERSONAL_ASSISTANTS_FAILURE";

export const INVITE_PERSONAL_ASSISTANT_REQUEST =
  "INVITE_PERSONAL_ASSISTANT_REQUEST";
export const INVITE_PERSONAL_ASSISTANT_SUCCESS =
  "INVITE_PERSONAL_ASSISTANT_SUCCESS";
export const INVITE_PERSONAL_ASSISTANT_FAILURE =
  "INVITE_PERSONAL_ASSISTANT_FAILURE";

export const UPDATE_PERSONAL_ASSISTANT_REQUEST =
  "UPDATE_PERSONAL_ASSISTANT_REQUEST";
export const UPDATE_PERSONAL_ASSISTANT_SUCCESS =
  "UPDATE_PERSONAL_ASSISTANT_SUCCESS";
export const UPDATE_PERSONAL_ASSISTANT_FAILURE =
  "UPDATE_PERSONAL_ASSISTANT_FAILURE";

//NOTIFICATIONS
export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

//MESSAGES
export const FETCH_CONVERSATIONS_REQUEST = "FETCH_CONVERSATIONS_REQUEST";
export const FETCH_CONVERSATIONS_SUCCESS = "FETCH_CONVERSATIONS_SUCCESS";
export const FETCH_CONVERSATIONS_FAILURE = "FETCH_CONVERSATIONS_FAILURE";

export const LISTING_BROADCAST_MESSAGE_COUNT =
  "LISTING_BROADCAST_MESSAGE_COUNT";

export const SET_BROADCAST_LISTING_ID = "SET_BROADCAST_LISTING_ID";

export const SEARCH_MESSAGES_REQUEST = "SEARCH_MESSAGES_REQUEST";
export const SEARCH_MESSAGES_SUCCESS = "SEARCH_MESSAGES_SUCCESS";
export const SEARCH_MESSAGES_FAILURE = "SEARCH_MESSAGES_FAILURE";
export const SET_SEARCH_MESSAGE_TERM = "SET_SEARCH_MESSAGE_TERM";
export const SET_SEARCH_BAR_FOCUS = "SET_SEARCH_BAR_FOCUS";

export const MESSAGING_AVAILABLE_MEMBERS_REQUEST =
  "MESSAGING_AVAILABLE_MEMBERS_REQUEST";
export const MESSAGING_AVAILABLE_MEMBERS_SUCCESS =
  "MESSAGING_AVAILABLE_MEMBERS_SUCCESS";
export const MESSAGING_AVAILABLE_MEMBERS_FAILURE =
  "MESSAGING_AVAILABLE_MEMBERS_FAILURE";
export const MESSAGING_AVAILABLE_MEMBERS_CLEAR =
  "MESSAGING_AVAILABLE_MEMBERS_CLEAR";

export const FETCH_CONVERSATION_MESSAGES_REQUEST =
  "FETCH_CONVERSATION_MESSAGES_REQUEST";
export const FETCH_CONVERSATION_MESSAGES_SUCCESS =
  "FETCH_CONVERSATION_MESSAGES_SUCCESS";
export const FETCH_CONVERSATION_MESSAGES_FAILURE =
  "FETCH_CONVERSATION_MESSAGES_FAILURE";
export const FETCH_CONVERSATION_MESSAGES_CLEAR =
  "FETCH_CONVERSATION_MESSAGES_CLEAR";

export const FETCH_CONVERSATION_PARTICIPANTS_REQUEST =
  "FETCH_CONVERSATION_PARTICIPANTS_REQUEST";
export const FETCH_CONVERSATION_PARTICIPANTS_SUCCESS =
  "FETCH_CONVERSATION_PARTICIPANTS_SUCCESS";
export const FETCH_CONVERSATION_PARTICIPANTS_FAILURE =
  "FETCH_CONVERSATION_PARTICIPANTS_FAILURE";

export const SET_ROLE_FOR_MESSAGES = "SET_ROLE_FOR_MESSAGES";

export const LOCK_MESSAGES_LIST = "LOCK_MESSAGES_LIST";
export const UNLOCK_MESSAGES_LIST = "UNLOCK_MESSAGES_LIST";

export const FETCH_CONVERSATION_PUBNUB_DATA_REQUEST =
  "FETCH_CONVERSATION_PUBNUB_DATA_REQUEST";
export const FETCH_CONVERSATION_PUBNUB_DATA_SUCCESS =
  "FETCH_CONVERSATION_PUBNUB_DATA_SUCCESS";
export const FETCH_CONVERSATION_PUBNUB_DATA_FAILURE =
  "FETCH_CONVERSATION_PUBNUB_DATA_FAILURE";

export const MESSAGING_ADD_MESSAGE_FROM_PUBNUB =
  "MESSAGING_ADD_MESSAGE_FROM_PUBNUB";

export const CONVERSATION_ADD_MESSAGE_REQUEST =
  "CONVERSATION_ADD_MESSAGE_REQUEST";
export const CONVERSATION_ADD_MESSAGE_SUCCESS =
  "CONVERSATION_ADD_MESSAGE_SUCCESS";
export const CONVERSATION_ADD_MESSAGE_FAILURE =
  "CONVERSATION_ADD_MESSAGE_FAILURE";
export const CONVERSATION_ADD_NOTE_MESSAGE_REQUEST =
  "CONVERSATION_ADD_NOTE_MESSAGE_REQUEST";
export const CONVERSATION_ADD_NOTE_MESSAGE_SUCCESS =
  "CONVERSATION_ADD_NOTE_MESSAGE_SUCCESS";
export const CONVERSATION_ADD_NOTE_MESSAGE_FAILURE =
  "CONVERSATION_ADD_NOTE_MESSAGE_FAILURE";

export const CREATE_DIRECT_CONVERSATION_REQUEST =
  "CREATE_DIRECT_CONVERSATION_REQUEST";
export const CREATE_DIRECT_CONVERSATION_SUCCESS =
  "CREATE_DIRECT_CONVERSATION_SUCCESS";
export const CREATE_DIRECT_CONVERSATION_FAILURE =
  "CREATE_DIRECT_CONVERSATION_FAILURE";

export const UPDATE_DIRECT_CONVERSATION_NAME_REQUEST =
  "UPDATE_DIRECT_CONVERSATION_NAME_REQUEST";
export const UPDATE_DIRECT_CONVERSATION_NAME_SUCCESS =
  "UPDATE_DIRECT_CONVERSATION_NAME_SUCCESS";
export const UPDATE_DIRECT_CONVERSATION_NAME_FAILURE =
  "UPDATE_DIRECT_CONVERSATION_NAME_FAILURE";
export const UPDATE_DIRECT_MESSAGE_HAS_READ = "UPDATE_DIRECT_MESSAGE_HAS_READ";

export const UNSET_CREATED_CONVERSATION_ID = "UNSET_CREATED_CONVERSATION_ID";

export const FETCH_UNREAD_MESSAGES_COUNTS_REQUEST =
  "FETCH_UNREAD_MESSAGES_COUNTS_REQUEST";
export const FETCH_UNREAD_MESSAGES_COUNTS_SUCCESS =
  "FETCH_UNREAD_MESSAGES_COUNTS_SUCCESS";
export const FETCH_UNREAD_MESSAGES_COUNTS_FAILURE =
  "FETCH_UNREAD_MESSAGES_COUNTS_FAILURE";

export const MARK_CONVERSATION_AS_READ = "MARK_CONVERSATION_AS_READ";
export const MARK_CONVERSATION_AS_READ_REQUEST =
  "MARK_CONVERSATION_AS_READ_REQUEST";
export const MARK_CONVERSATION_AS_READ_SUCCESS =
  "MARK_CONVERSATION_AS_READ_SUCCESS";
export const MARK_CONVERSATION_AS_READ_FAILURE =
  "MARK_CONVERSATION_AS_READ_FAILURE";

export const CONVERSATION_LIST_SCROLLED = "CONVERSATION_LIST_SCROLLED";
export const CONVERSATION_LIST_SCROLLED_SUCCESS =
  "CONVERSATION_LIST_SCROLLED_SUCCESS";
export const CONVERSATION_LIST_SCROLLED_FAILURE =
  "CONVERSATION_LIST_SCROLLED_FAILURE";

export const SEARCH_MESSAGES_SCROLLED = "SEARCH_MESSAGES_SCROLLED";
export const SEARCH_MESSAGES_SCROLLED_SUCCESS =
  "SEARCH_MESSAGES_SCROLLED_SUCCESS";
export const SEARCH_MESSAGES_SCROLLED_FAILURE =
  "SEARCH_MESSAGES_SCROLLED_FAILURE";

export const UPDATE_CONVERSATIONS_FILTER = "UPDATE_CONVERSATIONS_FILTER";

export const SCROLL_CHAT_THREAD_REQUEST = "SCROLL_CHAT_THREAD_REQUEST";
export const SCROLL_CHAT_THREAD_SUCCESS = "SCROLL_CHAT_THREAD_SUCCESS";
export const SCROLL_CHAT_THREAD_FAILURE = "SCROLL_CHAT_THREAD_FAILURE";

export const UPDATE_LAST_MESSAGE_FOR_CONVERSATION =
  "UPDATE_LAST_MESSAGE_FOR_CONVERSATION";

export const UPDATE_CONVERSATIONS_LIST = "UPDATE_CONVERSATIONS_LIST";

export const UPDATE_UNREAD_MESSAGES_COUNTERS =
  "UPDATE_UNREAD_MESSAGES_COUNTERS";

//NEW
export const MESSAGES_NEW_RECIPIENTS_ADD_RECIPIENT =
  "MESSAGES_NEW_RECIPIENTS_ADD_RECIPIENT";
export const MESSAGES_NEW_RECIPIENTS_REMOVE_RECIPIENT =
  "MESSAGES_NEW_RECIPIENTS_REMOVE_RECIPIENT";
export const MESSAGES_NEW_RECIPIENTS_CLEAR = "MESSAGES_NEW_RECIPIENTS_CLEAR";

export const MESSAGES_NEW_AVAILABLE_RECIPIENTS_REQUEST =
  "MESSAGES_NEW_AVAILABLE_RECIPIENTS_REQUEST";
export const MESSAGES_NEW_AVAILABLE_RECIPIENTS_SUCCESS =
  "MESSAGES_NEW_AVAILABLE_RECIPIENTS_SUCCESS";
export const MESSAGES_NEW_AVAILABLE_RECIPIENTS_FAILURE =
  "MESSAGES_NEW_AVAILABLE_RECIPIENTS_FAILURE";
export const MESSAGES_NEW_AVAILABLE_RECIPIENTS_CLEAR =
  "MESSAGES_NEW_AVAILABLE_RECIPIENTS_CLEAR";
export const MESSAGES_NEW_PRESERVE_GLOBAL_STATE =
  "MESSAGES_NEW_PRESERVE_GLOBAL_STATE";
export const MESSAGES_NEW_CLEAR_PRESERVE_GLOBAL_STATE =
  "MESSAGES_NEW_CLEAR_PRESERVE_GLOBAL_STATE";
export const MESSAGES_NEW_CLEAR_FORM = "MESSAGES_NEW_CLEAR_FORM";
export const MESSAGES_NEW_LEAVE_ONE_RECIPIENT =
  "MESSAGES_NEW_LEAVE_ONE_RECIPIENT";

export const MESSAGES_SHOWING_REQUEST = "MESSAGES_SHOWING_REQUEST";
export const MESSAGES_SHOWING_SUCCESS = "MESSAGES_SHOWING_SUCCESS";
export const MESSAGES_SHOWING_FAILURE = "MESSAGES_SHOWING_FAILURE";

export const MESSAGING_ADD_AGENT_TO_THE_TEAM_REQUEST =
  "MESSAGING_ADD_AGENT_TO_THE_TEAM_REQUEST";
export const MESSAGING_ADD_AGENT_TO_THE_TEAM_SUCCESS =
  "MESSAGING_ADD_AGENT_TO_THE_TEAM_SUCCESS";
export const MESSAGING_ADD_AGENT_TO_THE_TEAM_FAILURE =
  "MESSAGING_ADD_AGENT_TO_THE_TEAM_FAILURE";

export const MESSAGING_REMOVE_AGENT_FROM_TEAM_REQUEST =
  "MESSAGING_REMOVE_AGENT_FROM_TEAM_REQUEST";
export const MESSAGING_REMOVE_AGENT_FROM_TEAM_SUCCESS =
  "MESSAGING_REMOVE_AGENT_FROM_TEAM_SUCCESS";
export const MESSAGING_REMOVE_AGENT_FROM_TEAM_FAILURE =
  "MESSAGING_REMOVE_AGENT_FROM_TEAM_FAILURE";

export const MESSAGING_AVAILABLE_AGENTS_REQUEST =
  "MESSAGING_AVAILABLE_AGENTS_REQUEST";
export const MESSAGING_AVAILABLE_AGENTS_SUCCESS =
  "MESSAGING_AVAILABLE_AGENTS_SUCCESS";
export const MESSAGING_AVAILABLE_AGENTS_FAILURE =
  "MESSAGING_AVAILABLE_AGENTS_FAILURE";

// My account
export const MY_ACCOUNT_SET_INITIAL_PREFERENCES =
  "MY_ACCOUNT_SET_INITIAL_PREFERENCES";
export const MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_REQUEST =
  "MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_REQUEST";
export const MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_SUCCESS =
  "MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_SUCCESS";
export const MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_FAILURE =
  "MY_ACCOUNT_UNREAD_NOTIFICATIONS_COUNT_FAILURE";
export const MY_ACCOUNT_PREFERENCES_REQUEST = "MY_ACCOUNT_PREFERENCES_REQUEST";
export const MY_ACCOUNT_PREFERENCES_SUCCESS = "MY_ACCOUNT_PREFERENCES_SUCCESS";
export const MY_ACCOUNT_PREFERENCES_FAILURE = "MY_ACCOUNT_PREFERENCES_FAILURE";
export const MY_ACCOUNT_HIDE_WELCOME_SCREEN_REQUEST =
  "MY_ACCOUNT_HIDE_WELCOME_SCREEN_REQUEST";
export const MY_ACCOUNT_HIDE_WELCOME_SCREEN_SUCCESS =
  "MY_ACCOUNT_HIDE_WELCOME_SCREEN_SUCCESS";
export const MY_ACCOUNT_HIDE_WELCOME_SCREEN_FAILURE =
  "MY_ACCOUNT_HIDE_WELCOME_SCREEN_FAILURE";
export const MY_ACCOUNT_UPDATE_PREFERENCES = "MY_ACCOUNT_UPDATE_PREFERENCES";
export const MY_ACCOUNT_UPDATE_PREFERENCES_REQUEST =
  "MY_ACCOUNT_UPDATE_PREFERENCES_REQUEST";
export const MY_ACCOUNT_UPDATE_PREFERENCES_SUCCESS =
  "MY_ACCOUNT_UPDATE_PREFERENCES_SUCCESS";
export const MY_ACCOUNT_UPDATE_PREFERENCES_FAILURE =
  "MY_ACCOUNT_UPDATE_PREFERENCES_FAILURE";
export const UPDATE_UNREAD_NOTIFICATIONS = "UPDATE_UNREAD_NOTIFICATIONS";

// messageField
export const MESSAGE_FIELD_FOCUS = "MESSAGE_FIELD_FOCUS";
export const MESSAGE_FIELD_RECEIVED_FOCUS = "MESSAGE_FIELD_RECEIVED_FOCUS";
export const MESSAGE_FIELD_LOST_FOCUS = "MESSAGE_FIELD_LOST_FOCUS";
export const MESSAGE_FIELD_TEXT_CHANGE = "MESSAGE_FIELD_TEXT_CHANGE";
export const MESSAGE_FIELD_RESET = "MESSAGE_FIELD_RESET";

// ATTACHMENTS
export const ATTACHMENT_CREATE_REQUEST = "ATTACHMENT_CREATE_REQUEST";
export const ATTACHMENT_CREATE_SUCCESS = "ATTACHMENT_CREATE_SUCCESS";
export const ATTACHMENT_CREATE_FAILURE = "ATTACHMENT_CREATE_FAILURE";

export const ATTACHMENT_CONFIRM_REQUEST = "ATTACHMENT_CONFIRM_REQUEST";
export const ATTACHMENT_CONFIRM_SUCCESS = "ATTACHMENT_CONFIRM_SUCCESS";
export const ATTACHMENT_CONFIRM_FAILURE = "ATTACHMENT_CONFIRM_FAILURE";

export const ATTACHMENT_DESTROY_REQUEST = "ATTACHMENT_DESTROY_REQUEST";
export const ATTACHMENT_DESTROY_SUCCESS = "ATTACHMENT_DESTROY_SUCCESS";
export const ATTACHMENT_DESTROY_FAILURE = "ATTACHMENT_DESTROY_FAILURE";

export const ATTACHMENTS_CLEAR_UPLOADED = "ATTACHMENTS_CLEAR_UPLOADED";
export const ATTACHMENTS_CLEAR_ERRORS = "ATTACHMENTS_CLEAR_ERRORS";

export const ATTACHMENT_LISTING_CREATE_REQUEST =
  "ATTACHMENT_LISTING_CREATE_REQUEST";
export const ATTACHMENT_LISTING_CREATE_SUCCESS =
  "ATTACHMENT_LISTING_CREATE_SUCCESS";
export const ATTACHMENT_LISTING_CREATE_FAILURE =
  "ATTACHMENT_LISTING_CREATE_FAILURE";
export const ATTACHMENT_LISTING_DESTROY = "ATTACHMENT_LISTING_DESTROY";
export const ATTACHMENT_LISTING_CLEAR_ERRORS =
  "ATTACHMENT_LISTING_CLEAR_ERRORS";

export const ATTACHMENTS_UPLOAD_PROGRESS_UPDATE =
  "ATTACHMENTS_UPLOAD_PROGRESS_UPDATE";

// Brokerage settings
export const BROKERAGE_SETTINGS_USERS_REQUEST =
  "BROKERAGE_SETTINGS_USERS_REQUEST";
export const BROKERAGE_SETTINGS_USERS_SUCCESS =
  "BROKERAGE_SETTINGS_USERS_SUCCESS";
export const BROKERAGE_SETTINGS_USERS_FAILURE =
  "BROKERAGE_SETTINGS_USERS_FAILURE";

export const BROKERAGE_SETTINGS_INVITE_USER_REQUEST =
  "BROKERAGE_SETTINGS_INVITE_USER_REQUEST";
export const BROKERAGE_SETTINGS_INVITE_USER_SUCCESS =
  "BROKERAGE_SETTINGS_INVITE_USER_SUCCESS";
export const BROKERAGE_SETTINGS_INVITE_USER_FAILURE =
  "BROKERAGE_SETTINGS_INVITE_USER_FAILURE";

export const BROKERAGE_SETTINGS_INVITE_AGENT_REQUEST =
  "BROKERAGE_SETTINGS_INVITE_AGENT_REQUEST";
export const BROKERAGE_SETTINGS_INVITE_AGENT_SUCCESS =
  "BROKERAGE_SETTINGS_INVITE_AGENT_SUCCESS";
export const BROKERAGE_SETTINGS_INVITE_AGENT_FAILURE =
  "BROKERAGE_SETTINGS_INVITE_AGENT_FAILURE";

export const BROKERAGE_SETTINGS_UPDATE_INVITED_USER_REQUEST =
  "BROKERAGE_SETTINGS_UPDATE_INVITED_USER_REQUEST";
export const BROKERAGE_SETTINGS_UPDATE_INVITED_USER_SUCCESS =
  "BROKERAGE_SETTINGS_UPDATE_INVITED_USER_SUCCESS";
export const BROKERAGE_SETTINGS_UPDATE_INVITED_USER_FAILURE =
  "BROKERAGE_SETTINGS_UPDATE_INVITED_USER_FAILURE";

export const BROKERAGE_SETTINGS_REMOVE_INVITED_USER_REQUEST =
  "BROKERAGE_SETTINGS_REMOVE_INVITED_USER_REQUEST";
export const BROKERAGE_SETTINGS_REMOVE_INVITED_USER_SUCCESS =
  "BROKERAGE_SETTINGS_REMOVE_INVITED_USER_SUCCESS";
export const BROKERAGE_SETTINGS_REMOVE_INVITED_USER_FAILURE =
  "BROKERAGE_SETTINGS_REMOVE_INVITED_USER_FAILURE";

export const BROKERAGE_SETTINGS_SHOWING_COORDINATORS_REQUEST =
  "BROKERAGE_SETTINGS_SHOWING_COORDINATORS_REQUEST";
export const BROKERAGE_SETTINGS_SHOWING_COORDINATORS_SUCCESS =
  "BROKERAGE_SETTINGS_SHOWING_COORDINATORS_SUCCESS";
export const BROKERAGE_SETTINGS_SHOWING_COORDINATORS_FAILURE =
  "BROKERAGE_SETTINGS_SHOWING_COORDINATORS_FAILURE";
export const BROKERAGE_SETTINGS_REMOVE_SHOWING_COORDINATOR_SUCCESS =
  "BROKERAGE_SETTINGS_REMOVE_SHOWING_COORDINATOR_SUCCESS";

export const BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_REQUEST =
  "BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_REQUEST";
export const BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_SUCCESS =
  "BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_SUCCESS";
export const BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_FAILURE =
  "BROKERAGE_SETTINGS_UPDATE_SHOWING_COORDINATOR_ENABLED_FAILURE";

export const BROKERAGE_SETTINGS_ASSISTANTS_REQUEST =
  "BROKERAGE_SETTINGS_ASSISTANTS_REQUEST";
export const BROKERAGE_SETTINGS_ASSISTANTS_SUCCESS =
  "BROKERAGE_SETTINGS_ASSISTANTS_SUCCESS";
export const BROKERAGE_SETTINGS_ASSISTANTS_FAILURE =
  "BROKERAGE_SETTINGS_ASSISTANTS_FAILURE";

export const BROKERAGE_SETTINGS_CLEAR_ERRORS =
  "BROKERAGE_SETTINGS_CLEAR_ERRORS";

// System Settings
export const SYSTEM_SETTINGS_REQUEST = "SYSTEM_SETTINGS_REQUEST";
export const SYSTEM_SETTINGS_SUCCESS = "SYSTEM_SETTINGS_SUCCESS";
export const SYSTEM_SETTINGS_FAILURE = "SYSTEM_SETTINGS_FAILURE";
export const SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_ADD_MEMBER =
  "SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_ADD_MEMBER";
export const SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_REMOVE_MEMBER =
  "SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_REMOVE_MEMBER";
export const SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_UPDATE_MEMBER =
  "SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_UPDATE_MEMBER";
export const SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_UPDATE_TEAM_INSTRUCTIONS =
  "SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_UPDATE_TEAM_INSTRUCTIONS";
export const SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_CLEAR_MEMBER_DIFF =
  "SYSTEM_SETTINGS_OFFICE_INVOLVEMENT_CLEAR_MEMBER_DIFF";
export const SYSTEM_SETTINGS_UPDATE_ACCEPT_SHOWING_REQUEST =
  "SYSTEM_SETTINGS_UPDATE_ACCEPT_SHOWING_REQUEST";
export const SYSTEM_SETTINGS_UPDATE_DEFAULT_SYSTEM_SETTINGS =
  "SYSTEM_SETTINGS_UPDATE_DEFAULT_SYSTEM_SETTINGS";

//Feedback surveys
export const FEEDBACK_SURVEYS_REQUEST = "FEEDBACK_SURVEYS_REQUEST";
export const FEEDBACK_SURVEYS_SUCCESS = "FEEDBACK_SURVEYS_SUCCESS";
export const FEEDBACK_SURVEYS_FAILURE = "FEEDBACK_SURVEYS_FAILURE";
export const FEEDBACK_SURVEY_REQUEST = "FEEDBACK_SURVEY_REQUEST";
export const FEEDBACK_SURVEY_SUCCESS = "FEEDBACK_SURVEY_SUCCESS";
export const FEEDBACK_SURVEY_FAILURE = "FEEDBACK_SURVEY_FAILURE";
export const DEFAULT_SURVEY_SETTING_REQUEST = "DEFAULT_SURVEY_SETTING_REQUEST";
export const DEFAULT_SURVEY_SETTING_SUCCESS = "DEFAULT_SURVEY_SETTING_SUCCESS";
export const DEFAULT_SURVEY_SETTING_FAILURE = "DEFAULT_SURVEY_SETTING_FAILURE";
export const DEFAULT_SURVEY_SETTING_UPDATE_REQUEST =
  "DEFAULT_SURVEY_SETTING_UPDATE_REQUEST";
export const DEFAULT_SURVEY_SETTING_UPDATE_SUCCESS =
  "DEFAULT_SURVEY_SETTING_UPDATE_SUCCESS";
export const DEFAULT_SURVEY_SETTING_UPDATE_FAILURE =
  "DEFAULT_SURVEY_SETTING_UPDATE_FAILURE";

// Push notifications
export const PUSH_NOTIFICATIONS_BANNER_SHOWN =
  "PUSH_NOTIFICATIONS_BANNER_SHOWN";
export const PUSH_NOTIFICATIONS_MODAL_SHOWN = "PUSH_NOTIFICATIONS_MODAL_SHOWN";
export const PUSH_NOTIFICATIONS_REQUEST_SUBSCRIPTION =
  "PUSH_NOTIFICATIONS_REQUEST_SUBSCRIPTION";
export const PUSH_NOTIFICATIONS_DISMISSED = "PUSH_NOTIFICATIONS_DISMISSED";
export const PUSH_NOTIFICATIONS_BLOCKED = "PUSH_NOTIFICATIONS_BLOCKED";

export const PUSH_NOTIFICATIONS_SUBSCRIBE_REQUEST =
  "PUSH_NOTIFICATIONS_SUBSCRIBE_REQUEST";
export const PUSH_NOTIFICATIONS_SUBSCRIBE_SUCCESS =
  "PUSH_NOTIFICATIONS_SUBSCRIBE_SUCCESS";
export const PUSH_NOTIFICATIONS_SUBSCRIBE_FAILURE =
  "PUSH_NOTIFICATIONS_SUBSCRIBE_FAILURE";

export const PUSH_NOTIFICATIONS_UNSUBSCRIBE_REQUEST =
  "PUSH_NOTIFICATIONS_UNSUBSCRIBE_REQUEST";
export const PUSH_NOTIFICATIONS_UNSUBSCRIBE_SUCCESS =
  "PUSH_NOTIFICATIONS_UNSUBSCRIBE_SUCCESS";
export const PUSH_NOTIFICATIONS_UNSUBSCRIBE_FAILURE =
  "PUSH_NOTIFICATIONS_UNSUBSCRIBE_FAILURE";

// Property Search
export const PROPERTY_SEARCH_UPDATE_SELECTED =
  "PROPERTY_SEARCH_UPDATE_SELECTED";
export const PROPERTY_SEARCH_REQUEST = "PROPERTY_SEARCH_REQUEST";
export const PROPERTY_SEARCH_SUCCESS = "PROPERTY_SEARCH_SUCCESS";
export const PROPERTY_SEARCH_FAILURE = "PROPERTY_SEARCH_FAILURE";
export const PROPERTY_TOGGLE_SHOW_ALLOWED = "PROPERTY_TOGGLE_SHOW_ALLOWED";

// Routes
export const ROUTES_REQUEST = "ROUTES_REQUEST";
export const ROUTES_SUCCESS = "ROUTES_SUCCESS";
export const ROUTES_FAILURE = "ROUTES_FAILURE";

export const ROUTE_CREATE_REQUEST = "ROUTE_CREATE_REQUEST";
export const ROUTE_CREATE_SUCCESS = "ROUTE_CREATE_SUCCESS";
export const ROUTE_CREATE_FAILURE = "ROUTE_CREATE_FAILURE";

export const ROUTE_ADD_APPT_REQUEST = "ROUTE_ADD_APPT_REQUEST";
export const ROUTE_ADD_APPT_SUCCESS = "ROUTE_ADD_APPT_SUCCESS";
export const ROUTE_ADD_APPT_FAILURE = "ROUTE_ADD_APPT_FAILURE";

export const ROUTE_CLEAR_REQUEST = "ROUTE_CLEAR_REQUEST";
export const ROUTE_REQUEST = "ROUTE_REQUEST";
export const ROUTE_SUCCESS = "ROUTE_SUCCESS";
export const ROUTE_FAILURE = "ROUTE_FAILURE";
export const ROUTE_UPDATE = "ROUTE_UPDATE";
export const ROUTE_REMOVE_UNSAVED = "ROUTE_REMOVE_UNSAVED";

//Price Analysis Report
export const PRICE_ANALYSIS_REQUEST = "PRICE_ANALYSIS_REQUEST";
export const PRICE_ANALYSIS_SUCCESS = "PRICE_ANALYSIS_SUCCESS";
export const PRICE_ANALYSIS_FAILURE = "PRICE_ANALYSIS_FAILURE";
export const UPDATE_ANALYSIS_FILTERS = "UPDATE_ANALYSIS_FILTERS";

export const SET_MAPBOX_KEY = "SET_MAPBOX_KEY";

export const FETCH_DEFAULT_SHOWING_SETTING_REQUEST = "FETCH_DEFAULT_SHOWING_SETTING_REQUEST";
export const FETCH_DEFAULT_SHOWING_SETTING_SUCCESS = "FETCH_DEFAULT_SHOWING_SETTING_SUCCESS";
export const FETCH_DEFAULT_SHOWING_SETTING_FAILURE = "FETCH_DEFAULT_SHOWING_SETTING_FAILURE";
export const DEFAULT_SHOWING_SETTING_CHANGES = "DEFAULT_SHOWING_SETTING_CHANGE";
export const SAVE_DEFAULT_SHOWING_SETTING_CHANGES_REQUEST = "SAVE_DEFAULT_SHOWING_SETTING_CHANGES_REQUEST";
export const SAVE_DEFAULT_SHOWING_SETTING_CHANGES_SUCCESS = "SAVE_DEFAULT_SHOWING_SETTING_CHANGES_SUCCESS";
export const SAVE_DEFAULT_SHOWING_SETTING_CHANGES_FAILURE = "SAVE_DEFAULT_SHOWING_SETTING_CHANGES_FAILURE";
export const SAVE_INITIAL_DEFAULT_ADVANCE_NOTICE_DURATION = "SAVE_INITIAL_DEFAULT_ADVANCE_NOTICE_DURATION";
