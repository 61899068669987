import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Row, Textarea } from "~brokerage/components/shared/Form";
import { Header, Footer, Body } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";
import { useHistory, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { callApi } from "brokerage/middlewares/api";
import { setListingBroadcastMessageCount } from "~brokerage/actions/listings";
import { RiInformationLine } from "@remixicon/react";
import BroadcastRecipientFilter from "./BroadcastRecipientFilter";
import OfficeMembersFilter from "./OfficeMembersFilter";
import css from "./index.sass";

const ModalSendListingBroadcastMessage = props => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedOfficeMembers, setSelectedOfficeMembers] = useState([]);

  const { location, broadcastListingId } = props;

  const handleSubmit = (events, values) => {
    if (values.message.length === 0) {
      toast.error("Please write a valid message to be broadcasted.");
      return;
    }
    const reqData = {
      ...values,
      listing_id: broadcastListingId,
      excluded_agent_ids: selectedAgents.map(agent => agent.id),
      office_member_ids: selectedOfficeMembers.map(agent => agent.id)
    };
    setLoading(true);
    callApi(
      "messaging/conversations/showing/broadcast_messages",
      {},
      reqData,
      "post"
    ).then(
      response => {
        setLoading(false);
        props.dispatch(
          setListingBroadcastMessageCount(
            response.data.broadcast_message_count,
            broadcastListingId
          )
        );
        toast.success("Message Broadcasted Successfully");
        closeModalWindow();
      },
      error => {
        toast.error(
          error.response?.data?.error ||
            "Something went wrong editing the route. Please try again."
        );
        setLoading(false);
      }
    );
  };

  const closeModalWindow = () => {
    history.push({
      pathname: location.pathname,
      query: { ...location.query, modal: void 0 }
    });
  };

  return (
    <div className={css.base}>
      <Form onSubmit={handleSubmit} validate>
        <Header title="Broadcast Message" />

        <Body>
          <Row offset="0">
            <div className={css.broadcastInfo}>
              <RiInformationLine
                color="#999"
                size="1.2em"
                className={css.infoIcon}
              />
              <p className={css.broadcastInfoText}>
                Generate a message to all approved showing agents from the last
                30 days
              </p>
            </div>
          </Row>
          <Row offset="15">
            <Textarea
              name="message"
              placeholder="Enter Message to be Broadcasted to the approved showings"
            />
          </Row>
          <Row offset="15">
            <p className={css.omitRecipients}>
              Omit the following agents from this broadcast
            </p>
            <BroadcastRecipientFilter
              broadcastListingId={broadcastListingId}
              selectedAgents={selectedAgents}
              setSelectedAgents={setSelectedAgents}
            />
          </Row>
          <Row offset="15">
            <p className={css.omitRecipients}>
              CC office members (notifications only)
            </p>
            <OfficeMembersFilter
              selectedOfficeMembers={selectedOfficeMembers}
              setSelectedOfficeMembers={setSelectedOfficeMembers}
            />
          </Row>
        </Body>

        <Footer>
          <Button
            type="submit"
            float="right"
            variant="primary"
            disabled={loading}
          >
            Send
          </Button>
        </Footer>
      </Form>
    </div>
  );
};

ModalSendListingBroadcastMessage.propTypes = {
  location: PropTypes.func,
  dispatch: PropTypes.func,
  broadcastListingId: PropTypes.string,
  showing: PropTypes.object
};

function mapStateToProps(state) {
  const { broadcastListingId } = state.messages.list.messages;
  const { showings } = state.listings?.list?.listings?.entities?.find(
    e => e.id === broadcastListingId
  ) || { showings: {} };

  return {
    broadcastListingId,
    showings
  };
}
export default withRouter(
  connect(mapStateToProps)(ModalSendListingBroadcastMessage)
);
