import css from "./index.sass";
import React, { useContext, useEffect } from "react";
import { Row, Col } from "~brokerage/components/shared/Form";
import Calendar from "~brokerage/components/shared/Calendar";
import { inUsersTimezone } from "~brokerage/libs/helpers/TimeHelper";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import ListingSummary from "~brokerage/components/modals/ModalCreateShowing/ListingSummary";
import ListingCard from "~brokerage/components/modals/ModalCreateShowing/ListingCard";
import moment from "moment";
import { unavailableMessages } from "~brokerage/constants/showings/noShowReasons";
import { initialTimeForNewShowing } from "~brokerage/libs/helpers/TimeHelper";
import { DEFAULT_SHOWING_DURATION } from "~brokerage/constants/showings";
import { Dropdown } from "~brokerage/components/shared/Form";

const SelectDate = props => {
  const { times, setTimes } = useContext(ShowingContext);
  const {
    listing,
    appointmentReason,
    setAppointmentReason,
    setSelectedBuyerAgent,
    selectedBuyerAgent
  } = props;
  const activeShowing = times.find(t => t.id === listing.id) || {};
  let { startTime } = activeShowing;
  startTime = startTime ? moment(startTime) : initialTimeForNewShowing();
  const {
    showingsEnabled: { status: showable },
    canScheduleShowingOnBehalfOfBuyerAgent,
    permittedAppointmentReasons
  } = listing;
  const notShowableReason = !showable && unavailableMessages["default"];
  const appointmentReasons = Object.entries(permittedAppointmentReasons).map(
    ([value, label]) => ({
      label,
      value
    })
  );

  useEffect(() => {
    if (!startTime) {
      handleChange({ date: initialTimeForNewShowing().valueOf() });
    }
  }, []);

  const handleChange = ({ date }) => {
    const update = [...times];
    update[0] = {
      ...update[0],
      id: listing.id,
      startTime: inUsersTimezone(date).startOf("day").format(),
      position: 0,
      duration: update[0]?.duration || DEFAULT_SHOWING_DURATION
    };
    setTimes(update);
  };

  if (!startTime) {
    return <></>;
  }

  return (
    <div className={css.dateSelect}>
      <ListingSummary
        address={listing.address}
        status={listing.status}
        contingencyString={listing.contingencyString}
        specs={listing.specs}
        canScheduleShowingOnBehalfOfBuyerAgent={
          canScheduleShowingOnBehalfOfBuyerAgent
        }
        selectedBuyerAgent={selectedBuyerAgent}
        setSelectedBuyerAgent={setSelectedBuyerAgent}
      />
      <Row className={css.row}>
        <ListingCard
          price={listing.price}
          mls={listing.mls}
          photoUrl={listing.photoUrl}
          listingKey={listing.id}
        />
        <Col modifier="full600">
          <div className={css.calendarContainer}>
            {!showable ? (
              <div className={css.unavailableContainer}>
                <div className={css.reason}>{notShowableReason}</div>
                <p>
                  Showings for this property are not being managed by Aligned
                  Showings. <br />
                  <br /> Please review the Showing Instructions in the MLS or
                  contact the listing agent for other options.
                </p>
              </div>
            ) : (
              <>
                <Calendar
                  name="date"
                  selected={startTime}
                  onChange={handleChange}
                />
                <div className={css.typeRow}>
                  <p className={css.type}>Select Type:</p>
                  <Dropdown
                    name="type"
                    options={appointmentReasons}
                    selected={appointmentReason}
                    onChange={({ type }) => setAppointmentReason(type)}
                    variant="outline2Block"
                  />
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SelectDate;
