import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import ModalContactDetails from "~brokerage/app/components/shared/ModalContactDetails.jsx";
import { fetchShowingSellerTenantInvolvement } from "~brokerage/actions/showings";
import Contact from "./Contact";
import css from "./index.sass";

const ShowingContacts = ({ showingId, sellers, buyers, dispatch }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    dispatch(fetchShowingSellerTenantInvolvement(showingId));
  }, []);

  const openModal = contact => {
    setModalData(contact);
    setModalOpen(true);
  };

  return (
    <>
      {modalOpen && (
        <ModalContactDetails
          isModalOpen={modalOpen}
          contact={modalData}
          closeModal={() => setModalOpen(false)}
        />
      )}
      <ContactsSection
        title="Sellers / Tenants"
        contacts={sellers}
        openModal={openModal}
      />
      <ContactsSection title="Buyers" contacts={buyers} openModal={openModal} />
    </>
  );
};

const ContactsSection = ({ contacts, title, openModal }) => {
  if (contacts.length > 0)
    return (
      <div className={css.sectionWithBorder}>
        <h3 className={css.h3}>{title}</h3>
        <div className={css.items}>
          {contacts.map(contact => (
            <Contact
              key={contact.uuid}
              contact={contact}
              openModal={() => openModal(contact)}
            />
          ))}
        </div>
      </div>
    );
  else return null;
};

function mapStateToProps(state) {
  const { entities: sellers, buyers } =
    state.showings.single.sellerTenantInvolvement;
  return {
    sellers,
    buyers
  };
}

export default connect(mapStateToProps)(ShowingContacts);
