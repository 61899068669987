import { combineReducers } from "redux";

import * as types from "~brokerage/constants/actionTypes";
import { STATUS_CANCELLED } from "~brokerage/constants/showings/statuses";

const SHOWING_INITIAL_STATE = {
  isFetching: false,
  entity: {
    approvalNeeded: [],
    listing: {},
    listingAgents: [],
    status: "",
    instructions: {},
    internalInstructions: {
      message: ""
    },
    showingAgents: []
  },
  roleForLastShowing: "la"
};

import {
  STATUS_APPROVED,
  STATUS_DECLINED
} from "~brokerage/constants/showings/statuses";

function showing(state = SHOWING_INITIAL_STATE, action) {
  switch (action.type) {
    case types.SHOWING_REQUEST:
      return { ...state, isFetching: true };
    case types.SHOWING_SUCCESS:
      return {
        ...state,
        errors: null,
        isFetching: false,
        hasCancelShowingSubmitted: false,
        isChanging: false,
        isEditInstructionsSubmitting: false,
        editInstructionsError: null,
        entity: action.data.showing,
        roleForLastShowing: action.data.showing.isLa ? "la" : "ba"
      };
    case types.SHOWING_FAILURE:
      return { ...state, errors: action.errors, isFetching: false };
    case types.UPDATE_SHOWING_TEAM_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        entity: {
          ...state.entity,
          internalInstructions: {
            receivedAt: action.receivedAt,
            ...state.entity.instructions.internalInstructions,
            message: action.data.team.instructions
          }
        }
      };
    case types.UPDATE_SHOWING_TEAM_INSTRUCTIONS_FAILURE:
      return { ...state, errors: action.errors };
    case types.SHOWING_CHANGE_REQUEST:
      return { ...state, isChanging: true }; //TODO show spinner?
    case types.SHOWING_CHANGE_SUCCESS:
      return {
        ...state,
        isChanging: false,
        hasCancelShowingSubmitted: false,
        entity: { ...state.entity, ...action.changes }
      };
    case types.SHOWING_CHANGE_FAILURE:
      return { ...state, isChanging: false }; //TODO error handling
    case types.FETCH_PUBNUB_DATA_REQUEST:
      return { ...state };
    case types.FETCH_PUBNUB_DATA_SUCCESS:
      return { ...state, pubnubData: action.data.pubnubData };
    case types.FETCH_PUBNUB_DATA_FAILURE:
      return { ...state };
    case types.SHOWING_EDIT_INSTRUCTIONS_SUBMIT_REQUEST:
      return { ...state, isEditInstructionsSubmitting: true };
    case types.SHOWING_EDIT_INSTRUCTIONS_SUBMIT_SUCCESS:
      return {
        ...state,
        isEditInstructionsSubmitting: false,
        entity: {
          ...state.entity,
          instructions: action.data.showingInstructions
        }
      };
    case types.SHOWING_EDIT_INSTRUCTIONS_SUBMIT_FAILURE:
      return {
        ...state,
        isEditInstructionsSubmitting: false,
        editInstructionsError: action.errors || true
      };
    case types.SHOWINGS_DECLINE_SHOWING_REQUEST:
      return { ...state, isEditInstructionsSubmitting: true };
    case types.SHOWINGS_DECLINE_SHOWING_SUCCESS:
      return {
        ...state,
        isEditInstructionsSubmitting: false,
        hasCancelShowingSubmitted: false,
        entity: { ...state.entity, status: STATUS_DECLINED }
      };
    case types.SHOWINGS_DECLINE_SHOWING_FAILURE:
      return {
        ...state,
        isEditInstructionsSubmitting: false,
        editInstructionsError: action.errors || true
      };
    case types.SHOWINGS_APPROVE_SHOWING_REQUEST:
      return { ...state, isEditInstructionsSubmitting: true };
    case types.SHOWINGS_APPROVE_SHOWING_SUCCESS:
      return {
        ...state,
        isEditInstructionsSubmitting: false,
        hasCancelShowingSubmitted: false,
        entity: { ...state.entity, status: STATUS_APPROVED }
      };
    case types.SHOWINGS_APPROVE_SHOWING_FAILURE:
      return {
        ...state,
        isEditInstructionsSubmitting: false,
        editInstructionsError: action.errors || true
      };
    case types.SHOWING_CHANGED_BY_MESSAGE:
      return { ...state, entity: { ...state.entity, ...action.showing } };
    case types.SHOWING_ADD_AGENT_TO_MY_TEAM_SUCCESS:
      if (action.data.team == "la") {
        return {
          ...state,
          entity: {
            ...state.entity,
            listingAgents: [
              ...state.entity.listingAgents,
              action.data.newMember
            ]
          }
        };
      } else {
        return {
          ...state,
          entity: {
            ...state.entity,
            showingAgents: [
              ...state.entity.showingAgents,
              action.data.newMember
            ]
          }
        };
      }
    case types.SHOWING_CANCEL_SHOWING_REQUEST:
      return { ...state, hasCancelShowingSubmitted: true };
    case types.SHOWING_CANCEL_SHOWING_SUCCESS:
      return {
        ...state,
        hasCancelShowingSubmitted: false,
        entity: {
          ...state.entity,
          status: STATUS_CANCELLED
        }
      };
    case types.SHOWING_CANCEL_SHOWING_FAILURE:
      return { ...state, hasCancelShowingSubmitted: false };
    case types.SHOWING_HIDE_FEEDBACK_REQUEST:
      return {
        ...state,
        entity: {
          ...state.entity,
          isSurveyHiddenByUser: true
        }
      };
    case types.SHOWING_UNHIDE_FEEDBACK_SUCCESS:
      return {
        ...state,
        entity: {
          ...state.entity,
          isSurveyHiddenByUser: false
        }
      };
    case types.SHOWING_UPDATE_SHOWING_TYPE:
      return {
        ...state,
        entity: {
          ...state.entity,
          appointmentReason: action.newType
        }
      };
    default:
      return state;
  }
}

function messages(
  state = { entities: [], isChatScrollFetching: false, hasMoreMessages: true },
  action
) {
  switch (action.type) {
    case types.SHOWING_MESSAGES_REQUEST:
      return { ...state, isFetching: true, hasMoreMessages: true };
    case types.SHOWING_MESSAGES_SUCCESS:
      return { ...state, entities: action.data.messages, isFetching: false };
    case types.SHOWING_MESSAGES_FAILURE:
      return { ...state, isFetching: false }; //TODO error handling
    case types.SHOWING_MESSAGE_REQUEST:
      return { ...state, isSendingMessage: true }; // TODO: handle
    case types.SHOWING_MESSAGE_SUCCESS:
      return { ...state, isSendingMessage: false }; // TODO: handle properly
    case types.SHOWING_MESSAGE_FAILURE:
      return { ...state }; // TODO: handle
    case types.SHOWING_NOTE_MESSAGE_REQUEST:
      return { ...state, isSendingMessage: true }; // TODO: handle
    case types.SHOWING_NOTE_MESSAGE_SUCCESS:
      return { ...state, isSendingMessage: false }; // TODO: handle properly
    case types.SHOWING_NOTE_MESSAGE_FAILURE:
      return { ...state }; // TODO: handle
    case types.MESSAGING_ADD_MESSAGE_FROM_PUBNUB:
    case types.SHOWING_MESSAGES_ADD:
      return {
        ...state,
        entities: addMessageToList(state.entities, action.message)
      };
    case types.SCROLL_SHOWING_CHAT_THREAD_REQUEST:
      return { ...state, isChatScrollFetching: true };
    case types.SCROLL_SHOWING_CHAT_THREAD_SUCCESS:
      return {
        ...state,
        entities: action.data.messages.length
          ? action.data.messages.concat(state.entities)
          : state.entities,
        hasMoreMessages: Boolean(action.data.messages.length),
        isChatScrollFetching: false
      };
    case types.SCROLL_SHOWING_CHAT_THREAD_FAILURE:
      return { ...state, isChatScrollFetching: false, errors: action.errors };
    case types.UPDATE_SHOWING_MESSAGE_HAS_READ:
      return {
        ...state,
        entities: updateMessageHasRead(state.entities, action.message)
      };
    default:
      return state;
  }
}

export function addMessageToList(list, message) {
  if (!list.length) {
    return [message];
  }

  const lastMessageId = list[list.length - 1].id;
  if (lastMessageId > message.id) {
    for (let i = list.length - 1; i >= 0; i--) {
      if (list[i].id < message.id) {
        const nextList = list.slice();
        nextList.splice(i + 1, 0, message);
        return nextList;
      }
    }
    return [message].concat(list);
  } else if (lastMessageId < message.id) {
    return list.concat(message);
  }

  return list;
}

export function updateMessageHasRead(messages, message_id) {
  if (message_id.length === 0) {
    return messages;
  }

  const updatedMessages = messages.map(message => {
    if (message_id.includes(message.id)) {
      return { ...message, hasRead: true };
    }

    return message;
  });

  return updatedMessages;
}

function availableAgents(state = { entities: [] }, action) {
  switch (action.type) {
    case types.SHOWING_AVAILABLE_AGENTS_REQUEST:
      return { ...state, isFetching: true };
    case types.SHOWING_AVAILABLE_AGENTS_SUCCESS:
      return { ...state, entities: action.data.members, isFetching: false };
    case types.SHOWING_AVAILABLE_AGENTS_FAILURE:
      return { ...state, isFetching: false }; //TODO error handling
    case types.SHOWING_CLEAR_AVAILABLE_AGENTS:
      return { ...state, entities: [] };
    default:
      return state;
  }
}

const listingTeamMembersDefaultState = {
  entities: [],
  isFetching: false
};

function listingTeamMembers(state = listingTeamMembersDefaultState, action) {
  switch (action.type) {
    case types.SHOWING_LISTING_TEAM_MEMBERS_REQUEST:
      return { ...state, isFetching: true };
    case types.SHOWING_LISTING_TEAM_MEMBERS_SUCCESS:
      return { ...state, isFetching: false, entities: action.data.members };
    case types.SHOWING_LISTING_TEAM_MEMBERS_FAILURE:
      return { ...state, isFetching: false, errors: action.errors };
    case types.SHOWING_ADD_AGENT_TO_MY_TEAM_SUCCESS:
      if (action.data.team == "la") {
        return {
          ...state,
          entities: [...state.entities, action.data.newMember]
        };
      } else {
        return state;
      }
    case types.SHOWING_REMOVE_AGENT_FROM_MY_TEAM_SUCCESS:
      if (action.data.team == "la") {
        return {
          ...state,
          entities: state.entities.filter(a => a.id != action.data.agent.id)
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}

const buyerTeamMembersDefaultState = {
  entities: [],
  isFetching: false
};

function buyerTeamMembers(state = buyerTeamMembersDefaultState, action) {
  switch (action.type) {
    case types.SHOWING_BUYER_TEAM_MEMBERS_REQUEST:
      return { ...state, isFetching: true };
    case types.SHOWING_BUYER_TEAM_MEMBERS_SUCCESS:
      return { ...state, isFetching: false, entities: action.data.members };
    case types.SHOWING_BUYER_TEAM_MEMBERS_FAILURE:
      return { ...state, isFetching: false, errors: action.errors };
    case types.SHOWING_ADD_AGENT_TO_MY_TEAM_SUCCESS:
      if (action.data.team == "ba") {
        return {
          ...state,
          entities: [...state.entities, action.data.newMember]
        };
      } else {
        return state;
      }
    case types.SHOWING_REMOVE_AGENT_FROM_MY_TEAM_SUCCESS:
      if (action.data.team == "ba") {
        return {
          ...state,
          entities: state.entities.filter(a => a.id != action.data.agent.id)
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}

function sellerTenantInvolvement(
  state = { entities: [], buyers: [], isFetching: false },
  action
) {
  switch (action.type) {
    case types.SHOWING_SELLER_TENANT_INVOLVEMENT_REQUEST:
      return { entities: [], buyers: [], isFetching: true };
    case types.SHOWING_SELLER_TENANT_INVOLVEMENT_SUCCESS:
      return {
        ...state,
        entities: [...action.data.contacts],
        buyers: [...action.data.buyerContacts],
        isFetching: false
      };
    case types.SHOWING_SELLER_TENANT_INVOLVEMENT_FAILURE:
      return { ...state, isFetching: false, errors: action.errors };
    default:
      return state;
  }
}

function feedback(state = { entities: [], isVisible: false }, action) {
  switch (action.type) {
    case types.SHOWING_FEEDBACK_QUESTIONS_REQUEST:
      return { ...state, isFetching: true };
    case types.SHOWING_FEEDBACK_QUESTIONS_SUCCESS: {
      const { questions } = action.data;
      return {
        ...state,
        isFetching: false,
        entities: questions,
        isVisible: Boolean(questions.length)
      };
    }
    case types.SHOWING_FEEDBACK_QUESTIONS_FAILURE:
      return { ...state, isFetching: false, entities: [] };
    case types.SHOWING_HIDE_FEEDBACK_REQUEST:
      return { ...state, isVisible: false };
    case types.SHOWING_HIDE_FEEDBACK_SUCCESS:
      return {
        ...state,
        hasUncompletedSurveyQuestions: action.data.hasUncompletedSurveyQuestions
      };
    case types.SHOWING_UNHIDE_FEEDBACK_SUCCESS:
      return { ...state, hasUncompletedSurveyQuestions: null, isVisible: true };
    case types.SHOWING_REQUEST:
      return {
        ...state,
        entities: [],
        hasUncompletedSurveyQuestions: null,
        isVisible: false
      };
    default:
      return state;
  }
}

export default combineReducers({
  showing,
  messages,
  availableAgents,
  listingTeamMembers,
  buyerTeamMembers,
  sellerTenantInvolvement,
  feedback
});
