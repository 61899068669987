import React from "react";
import { connect } from "react-redux";
import { RiInformationLine } from "@remixicon/react";

import css from "./index.sass";
import AgentContact from "./AgentContact";
import ExternalContact from "./ExternalContact";

const AgentContactSection = ({ users, text, currentUser, sellers, buyers }) => {
  const { id: currentUserId } = currentUser;
  const contacts = users.filter(user => user.id != currentUserId);

  return (
    <>
      <div className={css.wrapper2}>
        <div className={css.header2}>Appointment Contacts</div>
        {contacts.map((contact, index) => {
          return <AgentContact user={contact} n={index + 1} />;
        })}
        <div className={css.footer2}>
          <RiInformationLine
            color="#999"
            size="1.2em"
            className={css.infoIcon}
          />{" "}
          {text}
        </div>
      </div>
      <ExternalContacts contacts={buyers} label="Buyers" />
      <ExternalContacts contacts={sellers} label="Sellers/Tenants" />
    </>
  );
};

const ExternalContacts = ({ contacts, label }) => {
  if (contacts.length > 0)
    return (
      <div>
        <h3 className={css.h3}>{label}</h3>
        {contacts.map(contact => (
          <ExternalContact {...contact} />
        ))}
      </div>
    );
  else return null;
};

const mapStateToProps = ({ currentUser, showings }) => {
  const { entities: sellers, buyers } = showings.single.sellerTenantInvolvement;
  return {
    currentUser,
    sellers,
    buyers
  };
};

export default connect(mapStateToProps)(AgentContactSection);
