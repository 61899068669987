import { isEmpty } from "lodash";
import { deepObjectKeysToSnakeCase } from "~brokerage/libs/helpers/ObjectHelper";

export const steps = [
  {
    id: "date",
    label: "Select Date",
    heading: "Select Showing Date"
  },
  {
    id: "time",
    label: "Select Time",
    heading: "Select Time"
  },
  {
    id: "send",
    label: "Review & Send",
    heading: "Review & Send"
  }
];

export const buildCreateShowingPayload = ({
  req: { id, duration, time, showingMessage },
  buyerAgentPlatformId,
  appointmentReason,
  buyers
}) => {
  return {
    listing_id: id,
    buyer_agent_user_id: buyerAgentPlatformId,
    appointment_reason: appointmentReason,
    showing_request: {
      duration,
      requested_time: time.format(),
      message: showingMessage
    },
    ...(!isEmpty(buyers) && {
      buyer_contacts: deepObjectKeysToSnakeCase(buyers)
    })
  };
};

export const getErrorMessage = ({ response }) => {
  const { data } = response || {};
  return (
    data?.error ||
    data?.message ||
    "Sorry, there was an error scheduling this showing. Please try again."
  );
};
