import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import Modal from "react-modal";
import classNames from "classnames";
import moment from "moment";

import { addClass, removeClass } from "~brokerage/libs/helpers/ClassNameHelper";
import { bindTransitionEnd } from "~brokerage/libs/helpers/EventHelper";
import {
  STATUS_NOT_SENT,
  STATUS_CANCELLED,
  STATUS_DECLINED
} from "~brokerage/constants/showings/statuses";
import { isNotEmpty } from "~brokerage/libs/helpers/ObjectHelper";
import {
  fetchShowing,
  fetchShowingMessages,
  fetchShowingListingTeamMembers,
  fetchShowingBuyerTeamMembers,
  fetchShowingSellerTenantInvolvement,
  cancelShowing,
  unhideFeedback
} from "~brokerage/actions/showings";
import MyTeam from "./MyTeam";
import SidebarAgents from "~brokerage/components/shared/SidebarAgents";
import SellerTenantDisplay from "~brokerage/components/shared/SellerTenantDisplay";
import InternalInstructions from "./InternalInstructions";
import ShowingDetails from "./ShowingDetails";
import StatusInlineList from "./StatusInlineList";
import ShowingDateTime from "./ShowingDateTime";
import Contacts from "./Contacts";
import SellerTenantInvolvement from "./SellerTenantInvolvement";
import BuyerInvolvement from "./BuyerInvolvement";
import Link from "~brokerage/components/shared/Link";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import Loader from "~brokerage/components/shared/Loader";
import ModalShowingDetailsContainer from "~brokerage/components/modals/ModalShowingDetailsContainer";
import ModalShowingDetails from "~brokerage/components/modals/ModalShowingDetails";
import { withRouter } from "react-router-dom";
import { ShowingEditRequestedTimeLink } from "./EditRequestedTimeLink";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import ShowingType from "./ShowingType";

const agentShape = {
  id: PropTypes.string,
  name: PropTypes.string,
  firstName: PropTypes.string,
  photoUrl: PropTypes.string
};

class Sidebar extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      editDetailsShown: false
    };
  }

  static propTypes = {
    showingId: PropTypes.string,
    showing: PropTypes.shape({
      id: PropTypes.string,
      listingKey: PropTypes.string,
      date: PropTypes.string,
      requestedTime: PropTypes.string,
      duration: PropTypes.number,
      status: PropTypes.string,
      listAgent: PropTypes.shape(agentShape),
      buyerAgent: PropTypes.shape(agentShape),
      showingAgents: PropTypes.arrayOf(PropTypes.shape(agentShape)),
      listingAgents: PropTypes.arrayOf(PropTypes.shape(agentShape)),
      contacts: PropTypes.array,
      instructions: PropTypes.object,
      internalInstructions: PropTypes.object,
      isPassed: PropTypes.bool,
      startTime: PropTypes.string,
      canEditShowingRequestedTime: PropTypes.bool
    }),
    isFetching: PropTypes.bool,
    hasCancelShowingSubmitted: PropTypes.bool,
    hasUncompletedSurveyQuestions: PropTypes.bool,
    isChanging: PropTypes.bool,
    availableAgents: PropTypes.array,
    availableAgentsIsFetching: PropTypes.bool,
    currentShowingRole: PropTypes.string,
    dispatch: PropTypes.func,
    onStatusChange: PropTypes.func,
    listingTeamMembers: PropTypes.array,
    listingTeamMembersAreFetching: PropTypes.bool,
    buyerTeamMembers: PropTypes.array,
    buyerTeamMembersAreFetching: PropTypes.bool,
    sellers: PropTypes.array,
    buyers: PropTypes.array,
    location: PropTypes.object,
    history: PropTypes.object,
    searchTerm: PropTypes.string
  };

  componentWillMount() {
    const { showingId } = this.props;

    this.props.dispatch(fetchShowingListingTeamMembers(showingId));
    this.props.dispatch(fetchShowingBuyerTeamMembers(showingId));
    this.props.dispatch(fetchShowingSellerTenantInvolvement(showingId));
  }

  componentWillReceiveProps(nextProps) {
    const { showingId: nextShowingId } = nextProps;

    if (nextShowingId != null && this.props.showingId !== nextShowingId) {
      this.props.dispatch(fetchShowingListingTeamMembers(nextShowingId));
      this.props.dispatch(fetchShowingBuyerTeamMembers(nextShowingId));
      this.props.dispatch(fetchShowingSellerTenantInvolvement(nextShowingId));
    }
  }

  componentDidMount() {
    document.addEventListener(
      "highlightEditStatus",
      this.handleHighlightEditStatus
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "handleHighlightEditStatus",
      this.handleHighlightEditStatus
    );
  }

  get isBA() {
    return this.props.currentShowingRole === "ba";
  }

  get isLA() {
    return this.props.currentShowingRole === "la";
  }

  get requiredAgents() {
    return [this.props.showing[this.isBA ? "buyerAgent" : "listAgent"]];
  }

  get myTeamAgents() {
    return this.props[`${this.isBA ? "buyer" : "listing"}TeamMembers`];
  }

  get myTeamAgentsAreFetching() {
    return this.props[
      `${this.isBA ? "buyer" : "listing"}TeamMembersAreFetching`
    ];
  }

  get otherTeamAgents() {
    return this.props[`${this.isBA ? "listing" : "buyer"}TeamMembers`];
  }

  get otherTeamAgentsAreFetching() {
    return this.props[
      `${this.isBA ? "listing" : "buyer"}TeamMembersAreFetching`
    ];
  }

  get otherTeamHeading() {
    return `${this.isBA ? "Listing" : "Showing"} Team`;
  }

  get isEditable() {
    const { status, isPassed, startTime } = this.props.showing;
    const isStartTimePassed = startTime && new Date(startTime) < new Date();
    return !(
      status === STATUS_CANCELLED ||
      status === STATUS_DECLINED ||
      isPassed ||
      isStartTimePassed
    );
  }

  get isSent() {
    const { status } = this.props.showing;
    return status !== STATUS_NOT_SENT;
  }

  get isDraft() {
    const { startTime } = this.props.showing;
    return !this.isSent && startTime;
  }

  get isExpired() {
    const { startTime } = this.props.showing;
    return moment(startTime).isBefore();
  }

  handleHighlightEditStatus = () => {
    if (this.isHighlighted) {
      return;
    }

    this.isHighlighted = true;

    addClass(this.editContainersOverlay, css.overlayShow);

    setTimeout(() => {
      const unbindEnter = bindTransitionEnd(this.editContainersOverlay, () => {
        unbindEnter();
        removeClass(this.editContainersOverlay, css.overlayShown);

        const unbindExit = bindTransitionEnd(this.editContainersOverlay, () => {
          unbindExit();
          removeClass(this.editContainersOverlay, css.overlayShow);
          this.isHighlighted = false;
        });
      });

      addClass(this.editContainersOverlay, css.overlayShown);
    }, 1);
  };

  unhideSurvey = () => {
    this.props.dispatch(unhideFeedback(this.props.showingId));
  };

  renderFeedbackButton = () => {
    const {
      showing: { displaySurvey, isSurveyHiddenByUser, isLa },
      hasUncompletedSurveyQuestions
    } = this.props;

    const showLeaveFeedbackButton =
      hasUncompletedSurveyQuestions !== false &&
      displaySurvey &&
      isSurveyHiddenByUser;

    if (!showLeaveFeedbackButton) return;

    return (
      <Button
        onClick={this.unhideSurvey}
        className={css.btnLeaveFeedback}
        variant="link"
      >
        {isLa ? "Record" : "Leave"} Feedback
      </Button>
    );
  };

  handleCancelConfirm = () => {
    this.props.history.push({
      ...location,
      query: { ...omit(location.query, "modal") }
    });

    this.props.dispatch(cancelShowing(this.props.showing.id));
  };

  renderTeamInstructions() {
    const { internalInstructions } = this.props.showing;

    return (
      <div
        className={classNames(css.sectionWithBorder, {
          noprint: !isNotEmpty(internalInstructions)
        })}
      >
        <h3 className={css.h3}>
          Team Instructions{" "}
          {this.isEditable && (
            <span className={css.btnEdit}>
              <Link
                to={`${this.props.location.pathname}?modal=team_instructions`}
              >
                <Icon name="edit" /> Edit
              </Link>
            </span>
          )}
        </h3>
        <InternalInstructions {...internalInstructions} />
      </div>
    );
  }

  renderShowingAgentsContacts() {
    const { showingAgents } = this.props.showing;

    if (showingAgents && showingAgents.length) {
      return (
        <div
          className={classNames(css.sectionWithBorder, {
            onlyprint: this.isBA
          })}
        >
          <h3 className={css.h3}>Showing Agents</h3>
          <Contacts contacts={showingAgents} />
        </div>
      );
    }
  }

  renderListingAgentsContacts() {
    const { listingAgents } = this.props.showing;

    if (listingAgents && listingAgents.length) {
      return (
        <div
          className={classNames(css.sectionWithBorder, {
            onlyprint: this.isLA
          })}
        >
          <h3 className={css.h3}>Listing Agents</h3>
          <Contacts contacts={listingAgents} />
        </div>
      );
    }
  }

  renderSellers() {
    const { sellers } = this.props;

    if (sellers && sellers.length > 0) {
      return (
        <div className={css.sectionWithBorder}>
          <h3 className={css.h3}>Sellers</h3>
          <SellerTenantInvolvement sellerTenantInvolvement={sellers} />
        </div>
      );
    }
  }

  renderBuyers() {
    const { buyers } = this.props;

    if (buyers && buyers.length > 0) {
      return (
        <div className={css.sectionWithBorder}>
          <h3 className={css.h3}>Buyers</h3>
          <BuyerInvolvement buyers={buyers} />
        </div>
      );
    }
  }

  renderMlsNumber() {
    const { mlsId } = this.props.showing;

    if (mlsId) {
      return (
        <div className={css.sectionWithBorder}>
          <h3 className={css.h3}>MLS #</h3>
          <Highlighter
            highlightClassName="Summary"
            searchWords={[this.props.searchTerm]}
            autoEscape={true}
            textToHighlight={mlsId}
          />
        </div>
      );
    }
  }

  refreshMessagesScreen = id => {
    this.props.dispatch(fetchShowing(id));
    this.props.dispatch(fetchShowingMessages(id));
  };

  renderShowingDateTime() {
    const { requestedTime, duration, id, canEditShowingRequestedTime, route } =
      this.props.showing;

    const hasRoute = !!route;

    if (!requestedTime || !duration) return;
    const editLink = (
      <span className={css.btnEdit}>
        <ShowingEditRequestedTimeLink
          onChange={() => this.refreshMessagesScreen(id)}
          showingId={id}
          hasRoute={hasRoute}
        />
      </span>
    );
    const showLink = this.isEditable && canEditShowingRequestedTime;

    return (
      <div className={css.sectionWithBorder}>
        <h3 className={css.h3}>Showing Date & Time {showLink && editLink}</h3>
        <ShowingDateTime time={requestedTime} duration={duration} />
        {this.renderFeedbackButton()}
      </div>
    );
  }

  renderShowingDetails() {
    const { instructions, status } = this.props.showing;
    const editable = this.isLA && this.isEditable;
    const editLink = (
      <span className={css.btnEdit}>
        <Link onClick={() => this.setState({ editDetailsShown: true })}>
          <Icon name="edit" /> Edit
        </Link>
      </span>
    );
    // Show for only Approved future listings

    return (
      !this.isExpired &&
      status === "approved" && (
        <div
          className={classNames(css.sectionWithBorder, {
            noprint: !isNotEmpty(instructions)
          })}
        >
          <h3 className={css.h3}>Showing Details {editable && editLink}</h3>
          <ShowingDetails showingDetails={instructions} />
        </div>
      )
    );
  }

  renderShowingSections() {
    if (!this.isSent) return null;

    const {
      showing: {
        isPassed,
        id: showingId,
        status,
        appointmentReason,
        canEditShowingRequestedTime,
        route,
        permittedAppointmentReasons
      },
      isChanging,
      onStatusChange
    } = this.props;

    return (
      <div>
        <div
          className={css.editContainers}
          ref={el => (this.editContainers = el)}
        >
          <div className={css.sectionWithBorder}>
            <h3 className={css.h3}>Showing Status</h3>
            <StatusInlineList
              showingId={showingId}
              status={status}
              disabled={!this.isEditable}
              isChanging={isChanging}
              onChange={onStatusChange}
              isBA={this.isBA}
              isLA={this.isLA}
              isPassed={isPassed}
              hasRoute={!!route}
              refreshCallback={() => this.refreshMessagesScreen(showingId)}
            />
          </div>
          <ShowingType
            showingId={showingId}
            appointmentReason={appointmentReason}
            isEditable={this.isEditable && canEditShowingRequestedTime}
            permittedAppointmentReasons={permittedAppointmentReasons}
          />
          {this.renderShowingDateTime()}
          {this.renderShowingDetails()}
          {this.renderTeamInstructions()}

          <i
            className={css.overlay}
            ref={el => (this.editContainersOverlay = el)}
          />
        </div>

        {this.renderShowingAgentsContacts()}
        {this.renderListingAgentsContacts()}
        {this.renderSellers()}
        {this.renderBuyers()}
        {this.renderMlsNumber()}
      </div>
    );
  }

  closeEditDetailsModal(refresh) {
    const { id } = this.props.showing;
    this.setState({ editDetailsShown: false });
    refresh && this.refreshMessagesScreen(id);
  }

  render() {
    const { showing, isFetching, hasCancelShowingSubmitted } = this.props;

    if (isFetching) {
      return <Loader modifier="size20" active />;
    }

    const isCancelEnabled =
      (this.isSent || this.isDraft) &&
      this.isEditable &&
      !hasCancelShowingSubmitted;

    return (
      <>
        <Modal
          className={css.modalContent}
          overlayClassName={css.modalOverlay}
          isOpen={this.state.editDetailsShown}
          onRequestClose={() => this.closeEditDetailsModal(false)}
        >
          <ModalShowingDetailsContainer
            onClose={refresh => this.closeEditDetailsModal(refresh)}
          >
            <ModalShowingDetails />
          </ModalShowingDetailsContainer>
        </Modal>
        <div className={css.base}>
          <div className={isCancelEnabled ? css.topConsideringCancel : css.top}>
            <div className={css.team}>
              <MyTeam
                showingId={showing.id}
                agents={this.myTeamAgents}
                isFetching={this.myTeamAgentsAreFetching}
                requiredAgents={this.requiredAgents}
                teamName={this.props.currentShowingRole}
              />
            </div>

            <div className={css.team}>
              <SidebarAgents
                heading={this.otherTeamHeading}
                agents={this.otherTeamAgents}
                isFetching={this.otherTeamAgentsAreFetching}
              />
            </div>
            <SellerTenantDisplay
              listingKey={showing.listingKey}
              showingId={showing.id}
            />
            {this.renderShowingSections()}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({ messages }) {
  const { searchTerm } = messages.list.messages;
  return {
    searchTerm
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
