import React from "react";
import css from "./ExternalContact.sass";

const ExternalContact = ({
  firstName,
  lastName,
  email,
  primaryPhone,
  secondaryPhone
}) => (
  <div className={css.contactWrapper}>
    <p className={css.info}>
      Name: {firstName} {lastName}
    </p>
    <p className={css.info}>
      Email: <a href={"mailto:" + email}>{email}</a>
    </p>
    <p className={css.info}>
      Phone: <a href={"tel:" + primaryPhone}>{primaryPhone}</a>
      {!!secondaryPhone && (
        <>
          , <a href={"tel:" + secondaryPhone}>{secondaryPhone}</a>
        </>
      )}
    </p>
  </div>
);

export default ExternalContact;
