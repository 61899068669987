import React, { useState, useCallback } from "react";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";

import { callApi } from "brokerage/middlewares/api";
import Icon from "~brokerage/components/shared/Icon";
import Button from "~brokerage/components/shared/Button";
import Autocomplete from "~brokerage/components/shared/Autocomplete";
import css from "./OfficeMembersFilter.sass";

const OfficeMembersFilter = ({
  selectedOfficeMembers,
  setSelectedOfficeMembers
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [people, setPeople] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const options = people.map(person => ({ value: person.id, label: `${person.name}` }));

  const handleSearch = async (searchInput) => {
    setIsFetching(true);
    await callApi("people", {filter: { search: searchInput, category: "brokerage"}}, {}, "get")
      .then(response => {
        setIsFetching(false);
        if (response.data && response.data.people) {
          setPeople(response.data.people);
        } else {
          toast.error("Invalid API response for agents search");
        }
      })
      .catch(err => {
        setIsFetching(false);
        toast.error(err);
      });
  };

  const delayedFetch = useCallback(debounce(handleSearch, 500), []);

  const handleChange = event => {
    const value = event.target.value;

    if (value.length > 2) {
      delayedFetch(value);
    }
    if(value.length === 0) {
      setPeople([]);
    }
    setSearchTerm(value);
  };

  const handleSelect = (option) => {
    if (!option.value) {
      return
    }
    const person = people.find(person => person.id === option.value);

    setSelectedOfficeMembers([...selectedOfficeMembers, person]);
    setSearchTerm("");
    setPeople([]);
  }

  const handleSubmit = () => {
    if (searchTerm.length > 2) {
      delayedFetch(searchTerm);
    }
  }

  const handleRemoveClick = (removedAgentId) => {
    const updatedAgentsList = selectedOfficeMembers.filter(agent => agent.id !== removedAgentId);
    setSelectedOfficeMembers(updatedAgentsList);
    setSearchTerm("");
  }

  const autocompleteHandleKeyDown = (event) => {
    const { target: { value: inputValue }, key } = event;

    if (!inputValue.length && key === "Backspace") {
      const agent = selectedOfficeMembers[selectedOfficeMembers.length - 1];

      if (agent) {
        handleRemoveClick(agent.id);
      }
    }
  }

  return (
    <div className={css.recipientsInput}>
      <>
        {selectedOfficeMembers.map((agent, index) => {
          return (
          <span key={`${index}_${agent.id}`} className={css.recipientContainer}>
            <div className={css.result}>
              {agent.name}
            </div>
          </span>
          )
        })}
      </>
      <Autocomplete
        value={searchTerm}
        options={options}
        onSelect={handleSelect}
        onChange={handleChange}
        scrollable={true}
        height={150}
        placeholder="Enter the office member name"
        fetching={isFetching}
        style={{ border: 'none', height: 'auto', padding: '0', maxWidth: '280px' }}
        onKeyDown={autocompleteHandleKeyDown}
      />
    </div>
  );
};

export default OfficeMembersFilter;
