import React, { useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { callApi } from "~brokerage/middlewares/api";
import {
  hideFeedback,
  fetchShowing,
  fetchShowingMessages
} from "~brokerage/actions/showings";
import ModalContents from "./ModalContents";
import { shouldShowFeedback } from "../utils";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxWidth: "600px",
    minWidth: "500px",
    maxHeight: "80%",
    minHeight: "50%",
    padding: "0px",
    overflow: "hidden"
  },
  overlay: { zIndex: 100 } //The popover is at 99
};

const FeedbackModal = ({
  showingId,
  showing,
  dispatch,
  broadcastEnabled,
  onBroadcastSettingChange
}) => {
  const hideSurvey = () => dispatch(hideFeedback(showingId));
  const [loading, setLoading] = useState(true);

  const [details, setDetails] = useState({});
  const [answers, setAnswers] = useState({});

  const getSurveyData = () => {
    !loading && setLoading(true);
    callApi(`showings/${showingId}/survey`, {}, {}, "get").then(response => {
      setDetails(response.data);
      setAnswers(
        Object.fromEntries(response.data.questions.map(({ id }) => [id, null]))
      );
      setLoading(false);
    });
  };

  const submitFeedback = () => {
    const optionAnswers = [];
    const textAnswers = [];
    Object.entries(answers).forEach(([key, value], index) => {
      if (value) {
        if (typeof value === "number") optionAnswers.push(value);
        else {
          const { text: questionText } = details.questions.find(
            ({ id }) => id === Number(key)
          );
          textAnswers.push({
            position: index,
            question: questionText,
            answer: value
          });
        }
      }
    });
    !loading && setLoading(true);
    callApi(
      `showings/${showingId}/survey`,
      {},
      { answers: optionAnswers, text_answers: textAnswers },
      "post"
    )
      .then(async response => {
        setLoading(false);
        await hideSurvey();
        await dispatch(fetchShowing(showingId));
        await dispatch(fetchShowingMessages(showingId));
      })
      .catch(error => {
        setLoading(false);
        toast.error(
          error.response?.data?.message ||
            "Could not submit feedback. Try again later."
        );
      });
  };

  const closeModal = () => {
    hideSurvey();
  };

  Modal.setAppElement(document.body);

  const clickOption = (question_id, answer_id) => {
    setAnswers({ ...answers, [question_id]: answer_id });
  };

  const onChangeTextAnswer = (question_id, answerText) => {
    setAnswers({ ...answers, [question_id]: answerText });
  };

  const isModalOpen = shouldShowFeedback(showing);
  return (
    <Modal
      isOpen={isModalOpen}
      onAfterOpen={getSurveyData}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <ModalContents
        loading={loading}
        details={details}
        showing={showing}
        selectedAnswers={answers}
        clickOption={clickOption}
        onChangeTextAnswer={onChangeTextAnswer}
        submitFeedback={submitFeedback}
        closeModal={closeModal}
        broadcastEnabled={broadcastEnabled}
        onBroadcastSettingChange={onBroadcastSettingChange}
      />
    </Modal>
  );
};

function mapStateToProps(state) {
  const { showing } = state.showings.single;

  return {
    showing: showing.entity
  };
}

export default connect(mapStateToProps)(FeedbackModal);
